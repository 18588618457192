import '@fontsource/nunito/200.css';
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
import '@fontsource/nunito/800.css';
import '@fontsource/nunito/900.css';
import '@fontsource/nunito/200-italic.css';
import '@fontsource/nunito/300-italic.css';
import '@fontsource/nunito/400-italic.css';
import '@fontsource/nunito/500-italic.css';
import '@fontsource/nunito/600-italic.css';
import '@fontsource/nunito/700-italic.css';
import '@fontsource/nunito/800-italic.css';
import '@fontsource/nunito/900-italic.css';
import '@fontsource/dosis/200.css';
import '@fontsource/dosis/300.css';
import '@fontsource/dosis/400.css';
import '@fontsource/dosis/500.css';
import '@fontsource/dosis/600.css';
import '@fontsource/dosis/700.css';
import '@fontsource/dosis/800.css';

export const sharedTypography = () => {
  return {
    h1: {
      fontSize: '18px',
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 600,
      cursor: 'default',
    },
    h2: {
      fontSize: '18px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 400,
      cursor: 'default',
    },
    h3: {
      fontSize: '16px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 600,
      cursor: 'default',
    },
    h4: {
      fontSize: '15.6px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '20px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 600,
      cursor: 'default',
    },
    h5: {
      fontSize: '15.2px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '20px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 400,
      cursor: 'default',
    },
    h6: {
      fontSize: '14.8px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 600,
      cursor: 'default',
    },
    subtitle1: {
      fontSize: '14.4px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 300,
      cursor: 'default',
    },
    subtitle2: {
      fontSize: '14.4px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 600,
      cursor: 'default',
    },
    body1: {
      fontSize: '16px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 300,
    },
    body2: {
      fontSize: '14px',
      fontFamily: 'Nunito',
      fontStyle: 'Normal',
      lineHeight: '20px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 300,
    },
    button: {
      fontSize: '14px',
      fontFamily: 'Nunito',
      fontStyle: 'Normal',
      lineHeight: '16px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 600,
    },
    caption: {
      fontSize: '12px',
      fontFamily: 'Nunito',
      fontStyle: 'Normal',
      lineHeight: '16px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 300,
    },
    overline: {
      fontSize: '12px',
      fontFamily: 'Nunito',
      fontStyle: 'Normal',
      lineHeight: '16px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 300,
    },
  };
};

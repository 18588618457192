import { useEffect, useState, useCallback } from 'react';

type ClickHandler<T> = (isDoubleClick: boolean, clickTarget?: T) => void;

type ClickTrigger<T> = (targetItem?: T) => void;

/***
 * a custom hook that allows a click handler to differentiate between single click and double click
 * @param onClick {Function} - a callback function with signature (isDoubleClick: boolean, clickTarget: T) => void
 * @param clickIntervalMs {Number} -
 * @returns {Function} - a handler function to trigger when an item is clicked
 */
const useDoubleClick = <T>(onClick: ClickHandler<T>, clickIntervalMs = 500): ClickTrigger<T> => {
  const [clickCount, setClickCount] = useState(0);
  const [clickTarget, setClickTarget] = useState<T>();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (clickCount === 1) {
        onClick(false, clickTarget);
        setClickCount(0);
      }
    }, clickIntervalMs);

    if (clickCount === 2) {
      onClick(true, clickTarget);
      setClickCount(0);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [onClick, clickIntervalMs, clickCount, clickTarget]);

  return useCallback(targetItem => {
    setClickCount(previousCount => previousCount + 1);
    setClickTarget(targetItem);
  }, []);
};

export default useDoubleClick;

import { call, all, put, fork, take, select, cancel, takeLatest } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { ApolloClient, gql, NormalizedCacheObject, ObservableSubscription } from '@apollo/client';
import { createApolloClient, createApolloWs } from '../api/apolloHelper';

import { sessionTokenSelector, authSelector, preferredLanguageSelector } from '../auth';
import { SESSION_TOKEN_PREVENT_STASH_VALUE, stashTokenInSession } from '../auth/oAuth';

import {
  configSelector,
  graphEndpointSelector,
  loginUrlSelector,
  logoutRedirectUrlSelector,
} from '../config/selector';

import {
  postNotificationCommand,
  setIsIdle,
  setCallLogout,
  setClickedLogout,
  setCallResetIdleTimer,
  setReloadPage,
  subscribeToEventsWebsocket,
  hideGlobalMessage,
  disposeGlobalMessageFollowUp,
} from './slice';

import { globalMessageFollowUpActionTypeSelector } from './selectors';

import { notificationCommands } from './notificationCommands';

import { postNotificationApi } from '../api/postNotificationApi';
import { aiwareEvent } from './eventTypes';
import { EventAction } from './interfaces';
import { ICurrentUser } from '../auth/interfaces';
import { Action } from '@reduxjs/toolkit';
import { MessageSeverity, showMessage } from '../snackbar';

import { AIWareFormatMessage } from '@aiware/os/helpers';

function goToPage(page: string) {
  window.location.href = page;
}

let subscriptionTask: any;

class SubscriptionAttemptsLimiter {
  limit;
  attempts;

  constructor(limit?: number) {
    if (limit && limit > 0 && limit <= 5) {
      this.limit = limit;
    } else {
      this.limit = 5; // default retries
    }
    this.attempts = 0;
  }

  recordAttempt() {
    this.attempts += 1;
  }

  canRetry() {
    this.recordAttempt();
    if (this.attempts > this.limit) {
      return false;
    } else {
      return true;
    }
  }
}

const wsAttemptsLimiter = new SubscriptionAttemptsLimiter();

function* websocketInitChannel() {
  if (wsAttemptsLimiter.canRetry()) {
    const user: ICurrentUser = yield select(authSelector);
    const userId = user?.userId;
    const sessionToken: string = yield select(sessionTokenSelector);
    const { graphEndpointWS } = yield select(configSelector);
    const loginUrl: string = yield select(loginUrlSelector);
    const logoutRedirectUrl: string = yield select(logoutRedirectUrlSelector);
    // open saga channel to get the notification from socket
    return eventChannel((emitter: (arg0: { type: string; payload: unknown }) => void) => {
      // init the connection here
      const clients: ApolloClient<NormalizedCacheObject>[] = [];
      const subscriptions: ObservableSubscription[] = [];
      const query = gql`
          subscription {
            notificationPosted(notificationMailboxId:"${userId}") {
              id
              body
              contentType
              flags
              createdDateTime
              readDateTime
              updatedDateTime
              eventType
            }
          }`;
      const link = createApolloWs(graphEndpointWS, sessionToken, userId);
      const client = createApolloClient(link);
      const subscription = client
        .subscribe({
          query,
        })
        .subscribe({
          next(data: { data: { notificationPosted: any } }) {
            const eventMessage = data?.data?.notificationPosted?.body || '{}';
            const eventType = data?.data?.notificationPosted?.eventType || '';
            if (data?.data?.notificationPosted?.contentType === 'json') {
              try {
                const parsedMessage = JSON.parse(eventMessage);
                if (eventType && eventType === aiwareEvent) {
                  const command = parsedMessage?.command;
                  const messageValue = parsedMessage?.value;
                  switch (command) {
                    case notificationCommands.setIsIdle:
                      emitter(setIsIdle(messageValue));
                      break;
                    case notificationCommands.setCallLogout:
                      emitter(setCallLogout(messageValue));
                      break;
                    case notificationCommands.setClickedLogout:
                      emitter(setClickedLogout(messageValue));
                      break;
                    case notificationCommands.setCallResetIdleTimer:
                      emitter(setCallResetIdleTimer(messageValue));
                      break;
                    case notificationCommands.goToLoginPage:
                      // block the stashed session tokens
                      stashTokenInSession(SESSION_TOKEN_PREVENT_STASH_VALUE);
                      if (
                        !(window as any)['__logout_requested'] &&
                        messageValue === window.navigator.userAgent
                      ) {
                        goToPage(logoutRedirectUrl || loginUrl);
                      }
                      break;
                    case notificationCommands.refreshPage:
                      emitter(setReloadPage(messageValue));
                      break;
                  }
                }
              } catch (error) {
                console.log('Expecting JSON', { error }, { eventMessage });
              }
            }
          },
        });

      clients.push(client);
      subscriptions.push(subscription);

      // unsubscribe function
      return () => {
        subscriptions.forEach(subscription => subscription.unsubscribe());
        clients.forEach(client => client.stop());
        // do whatever to interrupt the socket communication here
      };
    });
  } else {
    const locale: string = yield select(preferredLanguageSelector);
    const formatMessage = AIWareFormatMessage(locale);
    yield put(
      showMessage({
        content: formatMessage({
          id: 'ws-connection-attempt-limit-error',
          defaultMessage: 'SDK could not establish websocket connection. Please check with your org admin',
          description: 'Error message when the limit of ws connection attempts is exceeded',
        })!,
        severity: MessageSeverity.Error,
      })
    );
  }
}

function* performSubscriptionTask() {
  const channel: ICurrentUser &
    string & {
      graphEndpointWS: any;
    } = yield call(websocketInitChannel);
  while (true) {
    const action: Action<any> = yield take(channel);
    yield put(action);
  }
}

function* websocketSagas() {
  if (subscriptionTask) {
    yield cancel(subscriptionTask);
  }
  // this is a weird workaround for failing TS check, should have been a 1-liner
  const newSubsTask: unknown = yield fork(performSubscriptionTask);
  subscriptionTask = newSubsTask;
}

function* postNotification(action: EventAction) {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const user: ICurrentUser = yield select(authSelector);
  const token: string = yield select(sessionTokenSelector);
  const userId = user?.userId;

  const { ephemeral, contentType, eventType, ...payload } = action?.payload || {};

  postNotificationApi(graphEndpoint, userId, payload, ephemeral, contentType, eventType, token);
}

function* hideGlobalMessageSaga() {
  const followUpActionType: string = yield select(globalMessageFollowUpActionTypeSelector);
  if (followUpActionType) {
    yield put({ type: followUpActionType });
    yield put(disposeGlobalMessageFollowUp());
  }
}

export default function* eventsSaga() {
  yield all([
    takeLatest(subscribeToEventsWebsocket.type, websocketSagas),
    takeLatest(postNotificationCommand.type, postNotification),
    takeLatest(hideGlobalMessage.type, hideGlobalMessageSaga),
  ]);
}

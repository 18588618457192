import { ISagaModule } from 'redux-dynamic-modules-saga';
import snackbarReducer, { namespace } from './slice';

// actions
export { showMessage, clearMessage, setDuration, resetDuration, setIsLoaderVisible } from './slice';

// selectors
export { snackbarSelector } from './selector';

// export severity constants
export { MessageSeverity } from './interfaces';
export type { MessageData } from './interfaces';

export function getSnackbarModule(): ISagaModule<typeof snackbarReducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: snackbarReducer,
    },
    sagas: [],
    initialActions: [],
  };
}

interface Colors {
  primaryMain: string;
  secondaryMain: string;
  textPrimary: string;
  textSecondary: string;
  tableheadColor: string;
  tableRowSelectedBackground: string;
  buttonContainedPrimaryHoverBackground: string;
  buttonContainedPrimaryBackgroundColor: string;
  buttonDisabledColor: string;
  buttonDisabledBackground: string;
  buttonOutlinedBackground: string;
  linkColor: string;
  iconRootColor: string;
  iconRootHover: string;
  iconPrimaryHover: string;
}

/**
 *
 * @param overrides
 * @param colors
 * need @Ken Kaiser to define type for "defaultOverrides"
 */
type TNestedOverride = {
  [key in string]: string | boolean | number | TNestedOverride;
};

export const defaultOverrides: (c: Colors) => TNestedOverride = (colors: Colors) => {
  const overrides: TNestedOverride = {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: colors.tableheadColor,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: colors.tableheadColor,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: colors.tableRowSelectedBackground,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.iconRootColor,
          '&:hover': {
            color: colors.iconRootHover,
          },
          fontSize: '24px',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: colors.iconRootColor,
          '&:hover': {
            color: colors.iconRootHover,
          },
          fontSize: '24px',
        },
        colorPrimary: {
          color: colors.primaryMain,
          '&:hover': {
            color: colors.iconPrimaryHover,
          },
        },
        fontSizeSmall: {
          fontSize: '20px',
        },
        fontSizeLarge: {
          fontSize: '36px',
        },
      },
      // need secondary color here
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            color: colors.buttonDisabledColor,
            backgroundColor: colors.buttonDisabledBackground,
          },
          textTransform: 'capitalize',
          fontSize: '14px',
        },
        containedPrimary: {
          '&:not(:disabled):not(:hover)': {
            backgroundColor: `${colors.buttonContainedPrimaryBackgroundColor}!important`,
          },
          '&:hover': {
            backgroundColor: colors.buttonContainedPrimaryHoverBackground,
          },
        },
        contained: {
          '&:disabled': {
            backgroundColor: colors.buttonDisabledBackground,
            color: colors.buttonDisabledColor,
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: colors.buttonOutlinedBackground,
          },
        },
        outlined: {
          '&:disabled': { backgroundColor: colors.buttonDisabledBackground },
        },
        textSizeLarge: {
          fontSize: '15px',
        },
        textSizeSmall: {
          fontSize: '13px',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
        disableRipple: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
        disableRipple: true,
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fill: colors.buttonDisabledColor,
          '&$active': {
            fill: colors.secondaryMain,
          },
          '&$completed': {
            fill: colors.secondaryMain,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: colors.textSecondary,
          fontSize: '14px',
          fontWeight: 600,
          '&$active': {
            color: colors.textPrimary,
            fontSize: '14px',
            fontWeight: 600,
          },
          '&$completed': {
            color: colors.textPrimary,
            fontSize: '14px',
            fontWeight: 600,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '24px',
        },
        fontSizeSmall: {
          fontSize: '20px',
        },
        fontSizeLarge: {
          fontSize: '36px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.linkColor,
        },
      },
      defaultProps: {
        underline: 'none',
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          paddingLeft: '5px',
          paddingRight: '5px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.textPrimary,
          fontSize: '14px',
          borderRadius: '5px',
          fontWeight: 400,
          textTransform: 'capitalize',
          '&:hover': {
            backgroundColor: colors.buttonOutlinedBackground,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '36px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: colors.textPrimary,
          fontSize: '14px',
          fontWeight: 400,
        },
        primary: {
          color: colors.textPrimary,
          fontSize: '14px',
          fontWeight: 400,
        },
        secondary: {
          color: colors.textSecondary,
          fontSize: '14px',
          fontWeight: 400,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          textTransform: 'uppercase',
          userSelect: 'none',
        },
        h2: {
          userSelect: 'none',
        },
        h3: {
          userSelect: 'none',
        },
        h4: {
          userSelect: 'none',
        },
        h5: {
          userSelect: 'none',
        },
        h6: {
          userSelect: 'none',
        },
        body1: {
          fontSize: '16px',
          userSelect: 'none',
        },
        body2: {
          userSelect: 'none',
        },
        subtitle1: {
          userSelect: 'none',
        },
        subtitle2: {
          userSelect: 'none',
        },
        button: {
          userSelect: 'none',
        },
        caption: {
          color: colors.textSecondary,
          fontSize: '12px',
          userSelect: 'none',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: 0.5,
          borderBottomWidth: 0.5,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '11px',
        },
      },
      defaultProps: {
        enterDelay: 500,
        enterNextDelay: 500,
        enterTouchDelay: 1000,
        leaveDelay: 0,
        leaveTouchDelay: 0,
      },
    },
    MuiPopover: {
      defaultProps: {
        disableScrollLock: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& legend': {
            width: 'unset',
          },
        },
      },
    },
  };
  return overrides;
};

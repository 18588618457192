import { all, call, put, select, takeEvery, take, takeLatest, delay } from 'redux-saga/effects';
import { graphEndpointSelector, rootApiSelector, getConfigSuccess, selectApiConfigs } from '../config/index';
import {
  getUserRightRequest,
  handleFetchUserRequest,
  handleLogoutRequest,
  handleExtendTokenRequest,
  handleResetPasswordApi,
  handleRefreshTokenRequest,
  completeOrganizationInviteRequest,
  UNAUTHORIZED_401,
  handleImpersonateUserRequest,
  handleRequestPasswordReset,
} from '../api/authApi';
import { aiwareEvent, notificationCommands } from '../events';
import { postNotificationApi } from '../api/postNotificationApi';
import { IUser, IInitConfig, SdkEvents, ISdkEventManager } from '@aiware/js/interfaces';
import { updateUserSettingsApi } from '../api/apiUsers';
import { showMessage, setDuration, MessageSeverity } from '../snackbar/';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { CustomError } from '../api/authApi';
import { TGqlErr } from '../types';

import {
  fetchUserError,
  fetchUserRequest,
  fetchUserSuccess,
  logoutError,
  logoutRequest,
  logoutSuccess,
  refreshTokenRequest,
  extendTokenRequest,
  extendTokenSuccess,
  setTokenExpiration,
  updateTokenRequest,
  updateTokenError,
  updateTokenSuccess,
  updateUserLanguageStart,
  updateUserLanguageSuccess,
  resetPasswordRequest,
  resetPasswordError,
  resetPasswordSuccess,
  refreshTokenSuccess,
  oAuthGrantStart,
  oAuthGrantSkip,
  oAuthGrantReadyToStart,
  oAuthGrantSuccess,
  oAuthGrantFailure,
  sdkInitSuccess,
  sdkInitFailure,
  updateUserRecentApplicationsStart,
  updateUserRecentApplicationsSuccess,
  impersonateUserStart,
  impersonateUserError,
  requestPasswordResetStart,
  requestPasswordResetError,
} from './slice';

import { subscribeToEventsWebsocket } from '../events/slice';
import {
  TGraphResponse,
  TRestResponse,
  TResponseWithErrors,
  TRetryResponse,
  TCallEffectFunction,
  TUnknownData,
  TUnknownError,
} from '../config/interfaces';
import {
  sessionTokenSelector,
  userSelector,
  preferredLanguageSelector,
  authSelector,
  authStatusSelector,
  oAuthTokenSelector,
  oAuthStatusSelector,
  initStatusSelector,
  userRecentApplicationsSelector,
  impersonationStateSelector,
  requestPasswordResetStateSelector,
} from './selector';
import { initialConfigSelector, appIdSelector, oauthRedirectUriSelector } from '../config/selector';
import { login, stashTokenInSession, SESSION_TOKEN_PREVENT_STASH_VALUE } from './oAuth';
import { TStatus, Status, IUserRights, ICurrentUser } from './interfaces';
import { slice, uniq } from '@aiware/js/function';

const MAX_EXTEND_ATTEMPTS = 2;
const MAX_FETCH_USER_ATTEMPTS = 3;

const isTokenJwt = (token = ''): boolean => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    return false;
  }
  return parts.reduce((accum, part, index) => {
    if (index === 2) {
      return part.length > 0;
    }
    try {
      // test for base64 base64
      window.atob(part.replace(/-/g, '+').replace(/_/g, '/'));
      return true;
    } catch (_) {
      return false;
    }
  }, true);
};

// calls the specified function for the specified number of times until a successful response is received
function* handleRetries<D, E, R extends TRetryResponse<D, E>>(
  retryCount: number,
  retryDelayMs: number,
  retryFunc: (...args: unknown[]) => R,
  ...retryArgs: unknown[]
) {
  let retries = 0;
  let finished = false;
  let result: R = {} as R;

  while (!finished && retries < retryCount) {
    try {
      result = yield call(retryFunc, ...retryArgs);
      if (result.errors) {
        retries++;
        if (result.errors === UNAUTHORIZED_401) {
          finished = true;
        } else if (retries < retryCount) {
          yield delay(retryDelayMs);
        }
      } else {
        finished = true;
      }
    } catch (err: unknown) {
      (result as TResponseWithErrors<E>).errors = err as E;
      retries++;
      if (retries < retryCount) {
        yield delay(retryDelayMs);
      }
    }
  }
  return result;
}

export function* handleImpersonateUserSaga() {
  const apiRoot: string = yield select(rootApiSelector);
  const token: string = yield select(sessionTokenSelector);
  // @ts-ignore
  const impersonationState = yield select(impersonationStateSelector);
  const user = impersonationState.user;

  yield put(
    showMessage({
      content: `Attempting to impersonate ${user?.firstName} ${user?.lastName}`,
      severity: MessageSeverity.Info,
    })
  );

  const data: TRestResponse<{ token: string }, TUnknownError> = yield call(
    handleRetries as TCallEffectFunction<ICurrentUser, TUnknownError>,
    MAX_FETCH_USER_ATTEMPTS,
    2000,
    handleImpersonateUserRequest,
    apiRoot,
    user?.userId,
    user?.orgGuid
  );

  if (data.errors) {
    yield put(impersonateUserError());

    if (data.errors !== UNAUTHORIZED_401) {
      yield put(
        showMessage({
          content: 'Failed to impersonate user',
          severity: MessageSeverity.Error,
        })
      );
    } else {
      yield put(
        showMessage({
          content: 'You are not authorized to impersonate this user',
          severity: MessageSeverity.Error,
        })
      );
    }
  } else {
    yield put(
      showMessage({
        content: `Successfully impersonating ${user?.firstName} ${user?.lastName}`,
        severity: MessageSeverity.Success,
      })
    );
  }
}

export function* handleRequestPasswordResetSaga() {
  const apiRoot: string = yield select(rootApiSelector);
  // @ts-ignore
  const requestPasswordResetState = yield select(requestPasswordResetStateSelector);
  const token: string = yield select(sessionTokenSelector);
  const userId = requestPasswordResetState.userId;
  const email = requestPasswordResetState.email;

  const data: TRestResponse<{ token: string }, TUnknownError> = yield call(
    handleRetries as TCallEffectFunction<ICurrentUser, TUnknownError>,
    MAX_FETCH_USER_ATTEMPTS,
    2000,
    handleRequestPasswordReset,
    apiRoot,
    userId,
    token
  );

  if (data.errors) {
    yield put(requestPasswordResetError());

    if (data.errors !== UNAUTHORIZED_401) {
      yield put(
        showMessage({
          content: 'Failed to send password reset request',
          severity: MessageSeverity.Error,
        })
      );
    } else {
      yield put(
        showMessage({
          content: 'You are not authorized to send a password reset request to this user',
          severity: MessageSeverity.Error,
        })
      );
    }
  } else {
    yield put(
      showMessage({
        content: `Requested password reset for ${email} successfully!`,
        severity: MessageSeverity.Success,
      })
    );
  }
}

export function* handleFetchUserSaga() {
  const apiRoot: string = yield select(rootApiSelector);
  if (!apiRoot) {
    yield take(getConfigSuccess.type);
    yield put(fetchUserRequest());
  } else {
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    const locale: string = yield select(preferredLanguageSelector);
    const formatMessage = AIWareFormatMessage(locale);

    const data: TRestResponse<ICurrentUser, TUnknownError> = yield call(
      handleRetries as TCallEffectFunction<ICurrentUser, TUnknownError>,
      MAX_FETCH_USER_ATTEMPTS,
      2000,
      handleFetchUserRequest,
      apiRoot,
      token
    );
    let userRightsData: TGraphResponse<IUserRights, TUnknownError> = {};
    if (data.errors !== UNAUTHORIZED_401) {
      userRightsData = yield call(
        handleRetries as TCallEffectFunction<IUserRights, TUnknownError>,
        MAX_FETCH_USER_ATTEMPTS,
        2000,
        getUserRightRequest,
        graphEndpoint,
        token
      );
    }

    if (data.errors || userRightsData.errors) {
      yield put(fetchUserError());
      stashTokenInSession(SESSION_TOKEN_PREVENT_STASH_VALUE);
      if (data.errors !== UNAUTHORIZED_401) {
        yield put(
          showMessage({
            content: formatMessage({
              id: 'shared-redux.fetch-user.failure-message',
              defaultMessage: 'It looks like our servers are having issues. Please refresh your page.',
              description: 'Error message displayed when the user data cannot be fetched',
            })!,
            severity: MessageSeverity.Error,
          })
        );
      }
      return;
    }

    const userRights = userRightsData?.data?.me?.roles
      ?.map((right: { appName: string }) => right.appName)
      .filter((value: string, index: number, self) => self.indexOf(value) === index);
    if (userRights?.includes('admin')) {
      const rights = userRightsData?.data?.myRights?.operations || [];
      for (const right of rights) {
        if (right.includes('superadmin')) {
          userRights.push('superadmin');
          break;
        }
      }
    }
    const languageData = userRightsData?.data?.me?.userSettings?.find(
      (item: { key: string; value: string }) => item.key === 'preferredLanguage'
    );

    const openedApplications = userRightsData?.data?.me?.userSettings?.find(
      (item: { key: string; value: string }) => item.key === 'recentApplications'
    );

    const preferredLanguage = languageData?.value;
    // "en" is default
    const recentApplications = openedApplications?.value;
    data.preferredLanguage = preferredLanguage ? preferredLanguage : 'en';
    data.recentApplications = recentApplications ? recentApplications : '';
    yield put(
      fetchUserSuccess({
        ...data,
        roles: userRights,
        token: data.token || token,
        myRights: userRightsData?.data?.myRights?.operations,
        userSettings: userRightsData?.data?.me?.userSettings || [],
      } as any)
    );
    // initiate events saga
    yield put(subscribeToEventsWebsocket());
  }
}

function* logoutHelper(token: string) {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  // @ts-ignore
  const user: IUser = yield select(authSelector);
  // logout from all open tabs and windows
  yield call(stashTokenInSession, SESSION_TOKEN_PREVENT_STASH_VALUE);
  yield call(
    // @ts-ignore
    postNotificationApi,
    graphEndpoint,
    user?.userId,
    {
      eventType: aiwareEvent,
      command: notificationCommands?.setCallLogout,
      value: true,
      ephemeral: true,
      contentType: 'json',
    },
    true,
    'json',
    aiwareEvent,
    token
  );
}

export function* handleRefreshTokenSaga(action: ReturnType<typeof refreshTokenRequest>) {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const { token } = action.payload;
  try {
    const data: TGraphResponse<Record<string, unknown>, TGqlErr[]> = yield call(
      handleRefreshTokenRequest,
      token,
      graphEndpoint
    );
    if (!data?.errors) {
      yield put(refreshTokenSuccess({ ...data.data }));
      yield put(fetchUserRequest());
    } else {
      const locale: string = yield select(preferredLanguageSelector);
      const formatMessage = AIWareFormatMessage(locale);
      if ((data?.errors || []).find((e: TGqlErr) => (e?.data?.serviceMessage || '').includes('40'))) {
        // 4xx error
        yield put(setDuration(10000));
        yield put(
          showMessage({
            content: formatMessage({
              id: 'shared-redux.refresh-token-failure.4xx',
              defaultMessage: 'Could not refresh session',
              description: 'Error toast when token refresh fails with a 4xx error',
            })!,
            severity: MessageSeverity.Error,
          })
        );
        yield delay(10001);
        yield* logoutHelper(token);
      } else {
        yield put(
          showMessage({
            content: formatMessage({
              id: 'shared-redux.refresh-token-failure.non-4xx',
              defaultMessage: 'Could not refresh session. Service temporarily unavailable',
              description: 'Error toast when token refresh fails with non-4xx error',
            })!,
            severity: MessageSeverity.Error,
          })
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
}

// this will call the api if a uuid token can be obtained for the call, and it will retry for up to maxAttempts times
export function* handleUuidTokenOnlySaga<D, E>(
  token: string,
  apiRoot: string,
  uuidTokenOnlyApi: (token: string, apiRoot: string) => TRestResponse<D, E>,
  maxAttempts: number,
  jwtErrorMessage: string,
  handleError: (token: string, unknown: unknown) => unknown
) {
  let retries = 0;
  let finished = false;
  let requestError: unknown;
  let result: TRestResponse<D, E> | undefined;
  let currentToken = token;
  let currentTokenIsUuidToken;
  while (!finished && retries < maxAttempts) {
    currentTokenIsUuidToken = !isTokenJwt(currentToken);
    if (!currentTokenIsUuidToken && currentToken !== token) {
      // we retrieved a new token and it is still not a uuid token - bail out
      finished = true;
    } else if (currentTokenIsUuidToken) {
      // we have a uuid token and can make the api call
      try {
        result = yield call(uuidTokenOnlyApi, currentToken, apiRoot);
        finished = true;
      } catch (tokenErr) {
        requestError = tokenErr;
        retries++;
        if (retries < maxAttempts) {
          yield delay(5000);
        }
      }
    } else {
      // we have a jwt token, try to obtain a uuid token
      try {
        const data: TRestResponse<ICurrentUser, TUnknownError> = yield call(handleFetchUserRequest, apiRoot);
        if (typeof data?.token === 'string') {
          yield put(updateTokenSuccess({ token: data.token }));
          yield call(uuidTokenOnlyApi, data.token, apiRoot);
          currentToken = data.token;
        } else {
          // no uuid token could be fetched, abort
          yield put(
            showMessage({
              content: jwtErrorMessage,
              severity: MessageSeverity.Error,
            })
          );
          finished = true;
        }
      } catch (err) {
        // attempt to fetch a uuid token failed, abort
        yield put(
          showMessage({
            content: jwtErrorMessage,
            severity: MessageSeverity.Error,
          })
        );
        finished = true;
      }
    }
  }
  // handle any errors caught while calling the uuid-specific api
  if (requestError) {
    yield call(handleError, currentToken, requestError);
  }
  return result;
}

function* handleExtendTokenErrors(token: string, error: unknown) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  if (error instanceof CustomError && error.isAuthErr) {
    // 4xx error
    yield put(setDuration(10000));
    yield put(
      showMessage({
        content: formatMessage({
          id: 'shared-redux.extend-token-failure.4xx',
          defaultMessage: 'Could not extend session',
          description: 'Error toast when token extend fails with a 4xx error',
        })!,
        severity: MessageSeverity.Error,
      })
    );
    yield delay(10001);
    yield* logoutHelper(token);
  } else {
    console.log('ERR!  Could not extend session. Service temporarily unavailable');
    yield put(
      showMessage({
        content: formatMessage({
          id: 'shared-redux.extend-token-failure.non-4xx',
          defaultMessage: 'Could not extend session. Service temporarily unavailable',
          description: 'Error toast when token extension fails with non-4xx error',
        })!,
        severity: MessageSeverity.Error,
      })
    );
    yield delay(2000);
  }
}

type TExtendResponse = { token: string; tokenExpiration: string };

export function* handleExtendTokenSaga(action: ReturnType<typeof extendTokenRequest>) {
  const { token } = action.payload;
  const apiRoot: string = yield select(rootApiSelector);
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  const jwtErrorMessage = formatMessage({
    id: 'shared-redux.extend-token-failure.jwt',
    defaultMessage: 'Could not extend session. JWT tokens cannot be extended.',
    description: 'Error toast when token extend fails because JWT tokens cannot be extended',
  });
  const results: TRestResponse<TExtendResponse, TUnknownError> = yield call(
    handleUuidTokenOnlySaga as TCallEffectFunction<TExtendResponse, TUnknownError>,
    token,
    apiRoot,
    handleExtendTokenRequest,
    MAX_EXTEND_ATTEMPTS,
    jwtErrorMessage,
    handleExtendTokenErrors
  );
  if (results && !results.errors) {
    yield put(extendTokenSuccess({ token: results.token }));
    yield put(setTokenExpiration({ expirationTime: results.tokenExpiration }));
    stashTokenInSession(results.token);
  }
}

export function* handleLogoutSaga(action: ReturnType<typeof logoutRequest>) {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const { token } = action.payload;
  yield call(stashTokenInSession, SESSION_TOKEN_PREVENT_STASH_VALUE);
  const data: TGraphResponse<TUnknownData, TUnknownError> = yield call(
    handleLogoutRequest,
    token,
    graphEndpoint
  );
  if (!data?.errors) {
    yield put(logoutSuccess());
  } else {
    yield put(logoutError());
  }
}

export function* handleUpdateSessionToken(action: ReturnType<typeof updateTokenSuccess>) {
  const { token } = action.payload;
  if (token) {
    yield put(updateTokenSuccess({ token }));
  } else {
    yield put(updateTokenError());
  }
}

export function* handleUpdateUserLanguage(
  sdkEventManager: Partial<ISdkEventManager>,
  action: ReturnType<typeof updateUserLanguageStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    const { userId } = yield select(userSelector);

    if (!userId) {
      sdkEventManager.dispatch?.(
        SdkEvents.languageChange,
        sdkEventManager.createError?.('Changing user preferred language failed, user id is required'),
        null
      );
      yield put(
        showMessage({
          content: formatMessage({
            id: 'shared-redux.snackbar.languageSelectionError',
            defaultMessage: 'Something went wrong while updating preferred language',
            description: 'The error message pops up when language switch fails',
          })!,
          severity: MessageSeverity.Error,
        })
      );
      return;
    }

    const preferredLanguage = action.payload;
    const newPreferredLanguage: string = yield call(
      updateUserSettingsApi,
      graphEndpoint,
      token,
      userId,
      'preferredLanguage',
      preferredLanguage
    );

    sdkEventManager.dispatch?.(SdkEvents.languageChange, null, {
      preferredLanguage: newPreferredLanguage,
    });
    yield put(updateUserLanguageSuccess(newPreferredLanguage));
  } catch (e) {
    sdkEventManager.dispatch?.(
      SdkEvents.languageChange,
      sdkEventManager.createError?.('Changing user preferred language failed'),
      null
    );

    yield put(
      showMessage({
        content: formatMessage({
          id: 'shared-redux.snackbar.languageSelectionChangeError',
          defaultMessage: 'Something went wrong while updating preferred language',
          description: 'The error message pops up when language switch fails',
        })!,
        severity: MessageSeverity.Error,
      })
    );
    console.log(e);
  }
}

export function* handleResetPasswordSaga(action: ReturnType<typeof resetPasswordRequest>) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const { resetToken, password, username, organizationInviteId, apiUrl, baseUrl } = action.payload;
    const apiRoot: string = apiUrl ? apiUrl : yield select(rootApiSelector);
    const graphEndpoint: string = baseUrl ? baseUrl : yield select(graphEndpointSelector);

    if (organizationInviteId) {
      yield call(completeOrganizationInviteRequest, graphEndpoint, resetToken, organizationInviteId);
    }

    const response: { success: boolean; status: number; result: any } = yield call(
      handleResetPasswordApi as any,
      apiRoot,
      {
        resetToken,
        username,
        password,
        organizationInviteId,
      }
    );

    // TODO: remove typecast
    if (response?.success) {
      yield put(resetPasswordSuccess());
    } else {
      const resultMessage = response?.result?.message || '';
      let errorMessage = '';

      try {
        errorMessage = JSON.parse(resultMessage)?.description;
      } catch (_) {
        errorMessage =
          formatMessage({
            id: 'os-auth.reset-password.internalServerError',
            defaultMessage: 'An error has occurred.',
            description: 'Error message for the reset password',
          }) || 'An error has occurred.';
      }

      if (response.status === 401 && response.result?.errorCode === 'PasswordResetTokenExpired') {
        errorMessage =
          formatMessage({
            id: 'os-auth.reset-password.expireMessageDescriptive',
            defaultMessage:
              'The password reset link you used has expired. Please click the "Forgot Password" link on the login page to generate a new link.',
            description: 'Expired token error descriptive message for the reset password',
          }) ||
          'The password reset link you used has expired. Please click the "Forgot Password" link on the login page to generate a new link.';
      } else if (response.status === 403) {
        errorMessage = response.result?.message || errorMessage;
      } else if (response.status === 404) {
        errorMessage =
          formatMessage({
            id: 'os-auth.reset-password.expireMessage',
            defaultMessage: 'An error has occurred. The reset link might be expired.',
            description: 'Expired token error message for the reset password',
          }) || "An error has occurred. The reset link might be expired';";
      }

      yield put(resetPasswordError({ message: errorMessage }));
    }
  } catch (e) {
    console.log(e);
    yield put(
      resetPasswordError({
        message:
          formatMessage({
            id: 'os-auth.reset-password.internalError',
            defaultMessage: 'Could not reset your password.',
            description: 'Internal error message for the reset password',
          }) || 'Could not reset your password.',
      })
    );
  }
}

function* requestOAuthGrant() {
  const applicationId: string = yield select(appIdSelector);
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const { apiRoot } = apiConfigs;
  let token: string = yield select(sessionTokenSelector);
  const oAuthRedirectUri: string = yield select(oauthRedirectUriSelector);

  if (token) {
    yield put(oAuthGrantSuccess(token));
    yield put(fetchUserRequest());
    return;
  }

  try {
    const loginResult: Record<string, string> = yield call(
      login,
      `${apiRoot}/v1/admin/oauth/authorize?response_type=token&client_id=${applicationId}&redirect_uri=${oAuthRedirectUri}&scope=all`
    );
    token = loginResult['OAuthToken'] as any;
    yield put(oAuthGrantSuccess(token));
    yield put(fetchUserRequest());
  } catch (err) {
    console.log('oauth flow error', err);
    yield put(oAuthGrantFailure());
  }
}

function* callSdkInitCompleteCallbackOnError() {
  // @ts-ignore
  const config: IInitConfig = yield select(initialConfigSelector);
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  config?.onComplete?.(
    formatMessage({
      id: 'shared-redux.auth-saga.sdk-init-error-message',
      defaultMessage: 'An error occurred during initialization.',
      description:
        'The error message that is returned by the aiware.init callback function when an init error occurs',
    })
  );
}

function* watchOAuthReadiness() {
  while (true) {
    yield take([oAuthGrantReadyToStart.type, fetchUserSuccess.type, fetchUserError.type]);
    const authStatus: TStatus = yield select(oAuthStatusSelector);
    const loginStatus: TStatus = yield select(authStatusSelector);
    if (loginStatus === Status.loading) {
      continue;
    }
    // when oauth is ready, decide path based on result of user request
    if (authStatus === Status.ready) {
      switch (loginStatus) {
        case Status.success:
          yield put(oAuthGrantSkip());
          break;
        case Status.failure:
          yield put(oAuthGrantStart());
          break;
        default:
          break;
      }
    }
  }
}

export function* handleUpdateUserRecentApplications(
  action: ReturnType<typeof updateUserRecentApplicationsStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    const { userId } = yield select(userSelector);
    const previousApplications: string[] = yield select(userRecentApplicationsSelector);

    if (!userId) {
      yield put(
        showMessage({
          content: formatMessage({
            id: 'shared-redux.snackbar.updateRecentApplicationsError',
            defaultMessage: 'Something went wrong while updating recent applications',
            description: 'The error message pops up when updating recent applications fails',
          })!,
          severity: MessageSeverity.Error,
        })
      );
      return;
    }
    const recentApplicationsId = slice(uniq([action.payload, ...previousApplications]), 0, 6);
    const recentApplications: string = yield call(
      updateUserSettingsApi,
      graphEndpoint,
      token,
      userId,
      'recentApplications',
      JSON.stringify(recentApplicationsId)
    );
    yield put(updateUserRecentApplicationsSuccess(recentApplications));
  } catch (e) {
    yield put(
      showMessage({
        content: formatMessage({
          id: 'shared-redux.snackbar.updatingRecentApplicationsChangeError',
          defaultMessage: 'Something went wrong while updating recent applications',
          description: 'The error message pops up when updating recent applications fails',
        })!,
        severity: MessageSeverity.Error,
      })
    );
    console.log(e);
  }
}

function* watchInitAndAuthStatus() {
  while (true) {
    yield take([
      oAuthGrantSuccess.type,
      oAuthGrantSkip.type,
      oAuthGrantFailure.type,
      sdkInitSuccess.type,
      fetchUserSuccess.type,
      fetchUserError.type,
    ]);
    // @ts-ignore
    const config: IInitConfig = yield select(initialConfigSelector);
    const oAuthToken: string = yield select(oAuthTokenSelector);
    const authStatus: TStatus = yield select(oAuthStatusSelector);
    const initStatus: TStatus = yield select(initStatusSelector);
    const userRequestStatus: TStatus = yield select(authStatusSelector);
    const token: string = yield select(sessionTokenSelector);
    const locale: string = yield select(preferredLanguageSelector);
    const formatMessage = AIWareFormatMessage(locale);

    // wait to see if the user request succeeds or fails
    if (userRequestStatus === Status.loading) {
      continue;
    }
    // user request succeeded so oauth is not needed anymore
    if (userRequestStatus === Status.success) {
      switch (authStatus) {
        case Status.loading:
          config?.onComplete?.(null, token);
          yield put(oAuthGrantSuccess(token));
          break;
        case Status.skipped:
          config?.onComplete?.(null, token);
          break;
      }
      continue;
    }
    // check for oauth final status
    if (initStatus === Status.success) {
      switch (authStatus) {
        case Status.skipped:
          if (userRequestStatus === Status.failure) {
            config?.onComplete?.(
              formatMessage({
                id: 'shared-redux.auth-saga.auth-error-message',
                defaultMessage: 'An error occurred during authentication.',
                description:
                  'The error message that is returned by the aiware.init callback function when an authentication error occurs',
              })
            );
          } else {
            config?.onComplete?.(null, token);
          }
          break;
        case Status.success:
          config?.onComplete?.(null, oAuthToken);
          break;
        case Status.failure:
          config?.onComplete?.(
            formatMessage({
              id: 'shared-redux.auth-saga.oauth-error-message',
              defaultMessage: 'An error occurred during authentication.',
              description:
                'The error message that is returned by the aiware.init callback function when an oAuth error occurs',
            })
          );
          break;
        default:
          break;
      }
    }
  }
}

function* watchTokenChanges(sdkEventManager: Partial<ISdkEventManager>) {
  while (true) {
    yield take([
      fetchUserSuccess.type,
      updateTokenSuccess.type,
      updateTokenError.type,
      refreshTokenSuccess.type,
      setTokenExpiration.type,
      fetchUserSuccess.type,
      fetchUserError.type,
    ]);

    const token: string = yield select(sessionTokenSelector);
    const { tokenExpiration } = yield select(userSelector) as IUser;
    const userRequestStatus: TStatus = yield select(authStatusSelector);
    const authStatus: TStatus = yield select(oAuthStatusSelector);

    if (userRequestStatus === Status.failure && authStatus !== Status.ready) {
      sdkEventManager.dispatch?.(
        SdkEvents.tokenUpdated,
        sdkEventManager.createError?.('An error occurred during the update of the session token.')
      );
    } else {
      sdkEventManager.dispatch?.(SdkEvents.tokenUpdated, null, {
        token,
        tokenExpiration,
      });
    }
  }
}

export default function authSagaFactory(sdkEventManager?: Partial<ISdkEventManager>) {
  return function* authSaga() {
    yield all([
      takeLatest(fetchUserRequest.type, handleFetchUserSaga),
      takeLatest(updateTokenRequest.type, handleUpdateSessionToken),
      takeLatest(resetPasswordRequest.type, handleResetPasswordSaga),
      takeEvery(refreshTokenRequest.type, handleRefreshTokenSaga),
      takeEvery(extendTokenRequest.type, handleExtendTokenSaga),
      takeEvery(logoutRequest.type, handleLogoutSaga),
      takeEvery(impersonateUserStart.type, handleImpersonateUserSaga),
      takeEvery(requestPasswordResetStart.type, handleRequestPasswordResetSaga),
      takeEvery(updateUserLanguageStart.type, handleUpdateUserLanguage, sdkEventManager || {}),
      takeEvery(oAuthGrantStart.type, requestOAuthGrant),
      takeEvery(sdkInitFailure.type, callSdkInitCompleteCallbackOnError),
      takeEvery(updateUserRecentApplicationsStart.type, handleUpdateUserRecentApplications),
      watchOAuthReadiness(),
      watchInitAndAuthStatus(),
      watchTokenChanges(sdkEventManager || {}),
    ]);
  };
}

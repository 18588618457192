import { ISagaModule } from 'redux-dynamic-modules-saga';
import { rootSaga } from './exportEngineAssets.saga.root';
import exportEngineAssetsReducer, { namespace } from './exportEngineAssets.slice';

// actions
export {
  createExportEngineAssetsInit,
  createExportEngineAssetsSuccess,
  createExportEngineAssetsFailed,
  setExportRequestStatus,
} from './exportEngineAssets.slice';

// selectors
export { selectExportEngineAssets } from './exportEngineAssets.selectors';

export function getExportEngineAssetsModule(): ISagaModule<typeof exportEngineAssetsReducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: exportEngineAssetsReducer,
    },
    sagas: [rootSaga],
    initialActions: [],
  };
}

function getElement(elementId: string, widgetName?: string): HTMLElement {
  let element = document.getElementById(elementId);
  if (element) return element;
  else {
    element = document.createElement('div');
    element.id = elementId;

    if (widgetName === 'APP_BAR') {
      document.getElementsByTagName('body').item(0)?.prepend(element);
    } else {
      // appending the panel layers the newest panels on top of older mounted ones
      document.getElementsByTagName('body').item(0)?.append(element);
    }

    return element;
  }
}

export default getElement;

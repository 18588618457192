import { ISagaModule } from 'redux-dynamic-modules-saga';
import eventsReducer from './slice';
import { namespace } from './slice';
import eventsSaga from './saga';

// actions
export {
  setIsIdle,
  setCallLogout,
  setClickedLogout,
  setCallResetIdleTimer,
  postNotificationCommand,
  subscribeToEventsWebsocket,
  setMessageScriptAdded,
  setMessageScriptAvailable,
  showGlobalMessage,
  hideGlobalMessage,
} from './slice';

// selectors
export {
  isIdleSelector,
  callLogoutSelector,
  clickedLogoutSelector,
  callResetIdleTimerSelector,
  resetIdleTimerCallCounterSelector,
  reloadPageSelector,
  messageScriptAddedSelector,
  messageScriptAvailableSelector,
  globalMessageSelector,
} from './selectors';

export { notificationCommands } from './notificationCommands';
export { aiwareEvent } from './eventTypes';

export function getEventsModule(): ISagaModule<typeof eventsReducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: eventsReducer,
    },
    sagas: [eventsSaga],
  };
}

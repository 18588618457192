import { baseGraphQLApi } from './baseGraphQLApi';

export const getAppConfig = async (graphEndpoint: string, token?: string): Promise<unknown> => {
  const query = `query {
    staticAppConfig {
        apiRoot
        switchAppUrl
        graphEndpoint
        graphEndpointWS
        loginUrl
        adminAppUrl
        automateUrl
        cmsAppUrl
        automateControllerUrl
        controllerNodeRedImage
      }
    }`;

  try {
    return await baseGraphQLApi({ query, graphEndpoint, token } as any);
  } catch (error) {
    return {
      success: false,
    };
  }
};

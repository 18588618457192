import { type FC, type ReactElement, type PropsWithChildren } from 'react';

import { CssBaseline } from '@mui/material';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

import { store } from '@aiware/shared/store';
import { type IInitConfig } from '@aiware/js/interfaces';

interface State {
  configs: {
    initConfig: IInitConfig;
  };
}

interface BaselineStylesProps {
  fullHeight?: boolean; // This makes the fullHeight prop optional
}

export const BaselineStyles: FC<PropsWithChildren<BaselineStylesProps>> = ({
  children,
  fullHeight = false,
}) => {
  const state = store.getState() as Partial<State>;
  const initConfig = state?.configs?.initConfig || ({} as IInitConfig);
  const { scopedStyles } = initConfig;

  // only return the children when initConfig is empty
  if (Object.keys(initConfig).length === 0) {
    return children as ReactElement;
  }

  // scopedStyles must be explicitly set to false to continue using CssBaseline instead of ScopedCssBaseline
  return scopedStyles === false ? (
    <>
      <CssBaseline />
      {children}
    </>
  ) : (
    <ScopedCssBaseline
      sx={
        fullHeight ? { height: '100%', backgroundColor: 'transparent' } : { backgroundColor: 'transparent' }
      }
    >
      <>{children}</>
    </ScopedCssBaseline>
  );
};

export default BaselineStyles;

import type { ISagaModule } from 'redux-dynamic-modules-saga';
import configsSaga, { watchSessionTimeout } from './saga';
import configsReducer, { namespace } from './slice';

// interfaces
export type { IConfigState } from './interfaces';

// actions
export {
  getInitConfig,
  getConfigFail,
  getConfigSuccess,
  enableBetaFeatures,
  disableBetaFeatures,
  getSessionTimeout,
} from './slice';

// selectors
export {
  rootApiSelector,
  configSelector,
  switchApiSelector,
  graphEndpointSelector,
  loginUrlSelector,
  logoutUrlSelector,
  hidePasswordResetSelector,
  logoutRedirectUrlSelector,
  controllerNodeRedImageSelector,
  automateControllerUrlSelector,
  controllerUrlSelector,
  baseUrlSelector,
  cmsUrlSelector,
  initialConfigSelector,
  sessionTimeoutSelector,
  automateUrlSelector,
  betaFeaturesSelector,
  selectApiConfigs,
  appIdSelector,
  selectDesktopUrl,
  disableAppUnavailableModalSelector,
} from './selector';

export function getConfigModule(): ISagaModule<typeof configsReducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: configsReducer,
    },
    sagas: [configsSaga, watchSessionTimeout],
    initialActions: [],
  };
}

import { Component, PropsWithChildren } from 'react';

interface Attributes {
  role?: {
    value?: string;
  };
}

export const clickAwayListener = (event: Event) => {
  const isPendo = (event.target as Element)?.id?.includes('pendo');
  const isParentAppBarOrPanel = event
    ?.composedPath()
    ?.find?.(
      (whereWeClicked: EventTarget) =>
        (whereWeClicked as Element)?.id?.toLowerCase().includes('aiware-app-bar') ||
        (whereWeClicked as Element)?.id?.toLowerCase().includes('panel') ||
        (whereWeClicked as Element)?.id?.includes('APP_BAR') ||
        (whereWeClicked as Element)?.id?.includes('undefined') ||
        (whereWeClicked as Element)?.id?.includes('-root') ||
        (whereWeClicked as Element)?.attributes?.getNamedItem('role')?.value?.includes('presentation') ||
        (whereWeClicked as Element)?.attributes?.getNamedItem('role')?.value?.includes('tooltip')
    );

  const isLastParentBody = ((event?.composedPath() || []) as any)[0]?.['tagName'] === 'BODY';

  if (!(isParentAppBarOrPanel || isLastParentBody) && !isPendo) {
    // Feature disabled as requested by Lance
    //store.dispatch(unmountAllPanel('null'));
  }
};

type Props = PropsWithChildren<Record<never, never>>;

export class RefHolder extends Component<Props, unknown> {
  render() {
    return <div>{this.props.children}</div>;
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUIState } from './interfaces';

export const namespace = 'uiState';
export const DEFAULT_CONFIRM_PROMPT_LABEL = 'Are you sure you wish to exit? Progress will be lost.';

const initialState: IUIState = {
  isFullscreenEnabled: false,
  isConfirmCloseEnabled: false,
  confirmCloseLabel: DEFAULT_CONFIRM_PROMPT_LABEL,
};

const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setIsFullscreenEnabled(state: IUIState, action: PayloadAction<boolean>) {
      state.isFullscreenEnabled = action.payload;
    },
    setIsConfirmCloseEnabled(state: IUIState, action: PayloadAction<boolean>) {
      state.isConfirmCloseEnabled = action.payload;
      // Reset the prompt label
      if (!action.payload) {
        state.confirmCloseLabel = DEFAULT_CONFIRM_PROMPT_LABEL;
      }
    },
    setConfirmCloseLabel(state: IUIState, action: PayloadAction<string>) {
      state.confirmCloseLabel = action.payload;
    },
  },
});

export const { setIsFullscreenEnabled, setIsConfirmCloseEnabled, setConfirmCloseLabel } = slice.actions;

const reducer = slice.reducer;
export default reducer;

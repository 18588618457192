import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfigState, IGetConfigAction, IConfig } from './interfaces';
import { IInitConfig } from '@aiware/js/interfaces';

export const namespace = 'configs';

export const initialState: IConfigState = {
  initConfig: {} as IInitConfig,
  config: null,
  status: 'idle',
};

const configsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getInitConfig(state: IConfigState, action: IGetConfigAction) {
      state.initConfig = action.payload;
      state.status = 'loading';
    },
    getSessionTimeout(state: IConfigState, _action: PayloadAction<string>) {
      state.status = 'loading';
    },
    setSessionTimeout(state: IConfigState, action: PayloadAction<number>) {
      state.status = 'success';
      state.config!.sessionTimeout = action.payload;
    },
    getConfigFail(state: IConfigState) {
      state.status = 'failure';
    },
    getConfigSuccess(state: IConfigState, action: PayloadAction<IConfig>) {
      state.status = 'success';
      state.config = action.payload;
    },
    setInitApplicationId(state: IConfigState, action: PayloadAction<string>) {
      state.initConfig.applicationId = action.payload;
    },
    enableBetaFeatures(state: IConfigState) {
      state.initConfig = {
        ...state.initConfig,
        betaFeatures: true,
      };
    },
    disableBetaFeatures(state: IConfigState) {
      state.initConfig = {
        ...state.initConfig,
        betaFeatures: false,
      };
    },
  },
});

// Actions
export const {
  getInitConfig,
  getConfigFail,
  getConfigSuccess,
  enableBetaFeatures,
  disableBetaFeatures,
  getSessionTimeout,
  setSessionTimeout,
  setInitApplicationId,
} = configsSlice.actions;

const configsReducer = configsSlice.reducer;
export default configsReducer;

function pick(obj: { [key: string]: unknown }, params: string[]) {
  const result = {} as { [key: string]: unknown };
  for (const param of params) {
    if (obj[param]) {
      result[param] = JSON.parse(JSON.stringify(obj[param]));
    }
  }
  return result;
}
export default pick;

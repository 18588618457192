import { store } from '@aiware/shared/store';
import { unmountAllPanel as unmountAllPanelAction } from '@aiware/js/panel';

export default class AiwareSDKHelpers {
  /**
   * Checks whether the provided URL is valid.
   *
   * @param {String} str
   * @returns {Boolean}
   */
  isURLValid(str = '') {
    let url;
    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  /**
   * Unmounts all the open panels
   */
  unmountAllPanel() {
    store.dispatch(unmountAllPanelAction(''));
  }
}

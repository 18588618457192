import { setIsConfirmCloseEnabled, DEFAULT_CONFIRM_PROMPT_LABEL } from '@aiware/shared/redux';
import { store } from '@aiware/shared/store';

const sizes = {
  small: 380,
  medium: 560,
  large: 720,
  xlarge: 1020,
  xxlarge: 1280,
};

export enum EPanelSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
}

export type TPanelSize = `${EPanelSize}`;

export const getTabPanelWidthBySize = (size: string | undefined) => {
  return size ? sizes[size.toLowerCase() as TPanelSize] : undefined;
};

type TPromptUserBeforeExitResponse = {
  userDidConfirm: boolean;
  shouldPromptUser: boolean;
};

export function requestUserAlertConfirmation(): TPromptUserBeforeExitResponse {
  let message = DEFAULT_CONFIRM_PROMPT_LABEL;

  const response = {
    userDidConfirm: true,
    shouldPromptUser: false,
  };

  try {
    if ((window as any)['aiware']) {
      const state = (window as any)['aiware'].store.getState();

      if (state && state?.uiState && state?.uiState?.isConfirmCloseEnabled) {
        response.shouldPromptUser = true;
        message = state.uiState.confirmCloseLabel;
      }
    } else {
      console.error('aiware store not found in window');
    }
  } catch (error) {
    console.error('Error selecting isConfirmCloseEnabled from UI state', error);
  }

  if (!response.shouldPromptUser) {
    return response;
  }

  if (window.confirm(message) == true) {
    response.userDidConfirm = true;
    store.dispatch(setIsConfirmCloseEnabled(false));
  } else {
    response.userDidConfirm = false;
  }

  return response;
}

export * from './graphQLApi';

export * from './consts';

export * from './fetchVeritoneGraphQLApi';

export * from './intlHelpers';

export { EnumHelpers } from './enumHelpers';

export const ENGINE_OWNER_TYPES = {
  ORGANIZATION: 'organization',
  MINE: 'mine',
  FAVORITES: 'favorites',
  SHARED: 'shared',
  TEMPLATES: 'templates',
  NOTEBOOKS: 'notebooks',
};

export const dateFormats = {
  full: 'MMM Do, yyyy hh:mm A',
  short: 'dd/MM/yyyy',
  dateTimeShort: 'MM/dd/yyyy HH:mm:ss',
  shortTime: 'hh:mm a',
  engineUpdateFormat: 'd-LLL-yyyy',
  engineOverviewFormat: 'LLL d,yyyy',
};

export const ENGINE_FILTER_TYPES = {
  CERTIFIED: 'certified',
  CATEGORIES: 'categories',
  CLASSES: 'classes',
  NOTEWORTHY: 'noteworthy',
  TAGS: 'tags',
  PRICE: 'price',
  INDUSTRIES: 'industries',
  APPLICATIONS: 'applications',
  PUBLIC: 'public',
  DEVELOPERS: 'developers'
};

export const getSortName = (name: string) => {
  const initials = name.match(/\b\w/g) || [];
  if (initials.length > 1) {
    const results = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return results;
  }
  return name.substr(0, 2).toUpperCase();
};

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';
import { ICreateExportRequestInput } from '../types';

export const namespace = 'exportEngineAssets';

export type State = {
  exportRequestStatus: LoadingStatus;
  exportRequestAssertUri?: string;
};

export const initialState: State = {
  exportRequestStatus: 'idle',
  exportRequestAssertUri: undefined,
} as any;

export const exportEngineAssetsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    createExportEngineAssetsInit: (state, _action: PayloadAction<ICreateExportRequestInput>) => {
      state.exportRequestStatus = 'pending';
    },
    createExportEngineAssetsSuccess: (state, action: PayloadAction<string>) => {
      state.exportRequestStatus = 'success';
      state.exportRequestAssertUri = action.payload;
    },
    createExportEngineAssetsFailed: state => {
      state.exportRequestStatus = 'failure';
      state.exportRequestAssertUri = undefined as any;
    },
    setExportRequestStatus: (state, action: PayloadAction<LoadingStatus>) => {
      state.exportRequestStatus = action.payload;
    },
  },
});

export const {
  createExportEngineAssetsInit,
  createExportEngineAssetsSuccess,
  createExportEngineAssetsFailed,
  setExportRequestStatus,
} = exportEngineAssetsSlice.actions;

const exportEngineAssetsReducer = exportEngineAssetsSlice.reducer;
export default exportEngineAssetsReducer;

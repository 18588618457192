import { GraphQLError } from 'graphql';
import * as Sentry from '@sentry/react';

export async function logWithSentry<T>(
  response: { data: T; errors?: GraphQLError[] },
  request: { query: string; variables?: Record<string, unknown> }
) {
  for (const err of response.errors || []) {
    try {
      // Add scoped report details and send to Sentry
      Sentry.withScope(scope => {
        // Log query and variables as extras
        scope.setExtra('query', request.query);
        scope.setExtra('variables', request.variables);
        if (err.path) {
          // We can also add the path as breadcrumb
          scope.addBreadcrumb({
            category: 'query-path',
            message: err.path.join(' > '),
          });
        }
        Sentry.captureException(err);
      });
    } catch (sentryErr) {
      console.error('Error logging error to Sentry: ', sentryErr);
    }
  }
}

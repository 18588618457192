export default function guid() {
  const crypto = window.crypto;
  if (crypto) {
    const newNumber = [1e7, 1e3, 4e3, 8e3, 1e11];
    const newString = newNumber.join('-');
    return newString.replace(/[018]/g, (c: string) => cryptoGenerateString(c));
  }
  return '';
}

function cryptoGenerateString(c: string) {
  const convertedString = Number(c);
  const randomValues: Uint8Array = crypto.getRandomValues(new Uint8Array(1));
  const newCryptoNumber = convertedString ^ (randomValues[0]! & (15 >> (convertedString / 4)));
  return newCryptoNumber.toString(16);
}

// convert bytes to KB, MB, GB format
function formatBytes(bytes: number, decimals = 1): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(getBaseLog(k, bytes));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

// getBaseLog(2,8) return 3 since 2 x 2 x 2 = 8
function getBaseLog(x: number, y: number): number {
  return Math.log(y) / Math.log(x);
}

export default formatBytes;

import { baseGraphQLApi } from './baseGraphQLApi';
import { TRestResponse, TGraphResponse, TUnknownData, TUnknownError } from '../config/interfaces';
import { ICurrentUser, IUserRights } from '../auth/interfaces';

interface IUserLogin {
  username: string;
  password: string;
}

export const UNAUTHORIZED_401 = '401 Unauthorized';

export const handleLoginRequest = async (
  userLoginData: IUserLogin,
  graphEndpoint: string
): Promise<unknown> => {
  const { username, password } = userLoginData;
  const query = `
    mutation {
      userLogin(input: { userName: "${username}", password: "${password}" }) {
        apiToken
        token
        applications
      }
    }
  `;
  try {
    return await baseGraphQLApi({ query, graphEndpoint });
  } catch (error) {
    console.error(error);
  }
  return;
};

export const handleFetchUserRequest = async (
  API_URL: string,
  token?: string
): Promise<TRestResponse<ICurrentUser, TUnknownError>> => {
  try {
    const response = await fetch(`${API_URL}/v1/admin/current-user`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
    if (response.status === 401) {
      return { errors: UNAUTHORIZED_401 } as TRestResponse<ICurrentUser, TUnknownError>;
    }
    return await response.json();
  } catch (e) {
    console.error(e);
    return {
      errors: e,
    } as TRestResponse<ICurrentUser, typeof e>;
  }
};

export const handleImpersonateUserRequest = async (
  API_URL: string,
  userId?: string,
  organizationId?: string
) => {
  const url = `${API_URL}/v1/admin/impersonate/${userId}/${organizationId}?redirect=default`;
  window.location.href = url;
};

export const handleRequestPasswordReset = async (API_URL: string, userId?: string, token?: string) => {
  try {
    const url = `${API_URL}/api/admin/users/${userId}/force-password-reset`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });

    if (response.status === 401) {
      return { errors: UNAUTHORIZED_401 } as TRestResponse<ICurrentUser, TUnknownError>;
    }
    return Promise.resolve({});
  } catch (e) {
    console.error(e);
    return {
      errors: e,
    } as TRestResponse<ICurrentUser, typeof e>;
  }
};

export const handleRefreshTokenRequest = async (
  token: string,
  graphEndpoint: string
): Promise<TGraphResponse<TUnknownData, TUnknownError>> => {
  const query = `
    mutation {
      refreshToken(token: "${token}") {
        apiToken
        token
      }
    }
  `;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    console.error(error);
    return {
      errors: error,
    };
  }
};

export class CustomError extends Error {
  isAuthErr;
  message;
  response;

  constructor(isAuthErr: boolean, message: string, response?: any) {
    super(message);
    this.isAuthErr = isAuthErr;
    this.message = message;
    this.response = response;
  }
}

export const handleExtendTokenRequest = async (token: string, API_URL: string): Promise<unknown> => {
  const response = await fetch(`${API_URL}/v1/admin/token/${token}/extend`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  });
  if (response.ok) {
    return response.json();
  } else {
    let err;
    const message = 'Could not extend token';
    if (Math.trunc(response.status / 100) === 4) {
      err = new CustomError(true, message, response);
    } else {
      err = new CustomError(false, message, response);
    }
    throw err;
  }
};

export const handleResetPasswordApi = async (
  apiRoot: string,
  {
    resetToken,
    username,
    password,
    organizationInviteId,
  }: {
    resetToken: string;
    username: string;
    password: string;
    organizationInviteId?: string;
  }
): Promise<unknown> => {
  const url = `${apiRoot}/v1/admin/${organizationInviteId ? 'org-invite/password/reset' : 'password/reset'}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: resetToken,
        userName: username,
        password,
        ...(organizationInviteId && { organizationInviteId }),
      }),
    });

    if (response.status === 204 || response.ok) {
      return {
        success: true,
      };
    }

    const result = await response.json();
    return {
      success: false,
      status: response.status,
      result,
    };
  } catch (e) {
    console.log(e);

    return {
      success: false,
    };
  }
};

export const handleLogoutRequest = async (token: string, graphEndpoint: string): Promise<unknown> => {
  const query = `
    mutation {
      userLogout(token: "${token}")
    }
  `;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getUserRightRequest = async (
  graphEndpoint: string,
  token?: string
): Promise<TGraphResponse<IUserRights, TUnknownError>> => {
  const query = `
    query {
      me {
        roles {
          appName
          description
        }
        userSettings {
          key
          value
        }
      }
      myRights {
        operations
      }
    }
  `;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token } as any);
  } catch (error) {
    console.error(error);
    return {
      errors: error,
    };
  }
};

export const completeOrganizationInviteRequest = async (
  baseUrl: string,
  token: string,
  organizationInviteId: string
): Promise<{ id: string; status: string }> => {
  const variables = {
    input: {
      organizationInviteId,
      applicationRoles: [],
      action: 'complete',
    },
  };
  const operationName = 'completeOrganizationInvite';
  const name = 'updateOrganizationInvite';
  const query = `
    mutation ${operationName}($input: UpdateOrganizationInviteInput!){
      ${name}(input: $input){
        id
        status
      }
    }
  `;

  const result = await baseGraphQLApi<{
    [name]: {
      id: string;
      status: string;
    };
  }>({
    query,
    operationName,
    variables,
    graphEndpoint: baseUrl,
    token,
  });

  return result[name];
};

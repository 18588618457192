let _authWindow: Window | null = null;
let _authWindowListener: EventListener | null = null;
let _OAuthToken: string | null = null;

const _POLL_TIME = 500;

const cleanupAuthWindow = () => {
  if (_authWindow && _authWindow.close) {
    _authWindow.close();
  }
  _authWindow = null;
};

const removeAuthWindowListener = () => {
  if (_authWindowListener) {
    window.removeEventListener('message', _authWindowListener);
    _authWindowListener = null;
  }
};

interface AuthWindowListenerEvent<T, U> extends Event {
  origin?: string;
  data?: {
    error: T;
    OAuthToken?: U;
    token?: string;
  };
}

interface AuthTokenResponse {
  OAuthToken: string;
}

type AuthTokenResolver = (value: AuthTokenResponse | PromiseLike<AuthTokenResponse>) => void;

type AuthTokenRejecter = (reason?: Error) => void;

const createAuthWindowListener = (
  OAuthURI: string,
  resolve: AuthTokenResolver,
  reject: AuthTokenRejecter
): EventListener => {
  removeAuthWindowListener();
  return function authWindowListener(event: AuthWindowListenerEvent<unknown, string>) {
    const uri = new URL(window.location.href);
    if (event.origin?.startsWith(`${uri.protocol}//${uri.hostname}`)) {
      const message = event.data;
      // reject the login promise if there's any oauth2 error from widget-server
      if (message?.error) {
        removeAuthWindowListener();
        cleanupAuthWindow();
        reject(new Error('Veritone OAuth2 Error: ' + message.error));
        return;
      }

      // resolve the login promise if a token is present
      if (message?.OAuthToken) {
        _OAuthToken = message.OAuthToken;
        removeAuthWindowListener();
        cleanupAuthWindow();
        resolve({ OAuthToken: _OAuthToken });
        return;
      }
    }
  };
};

const askOAuthServerForToken = (OAuthURI: string, resolve: AuthTokenResolver, reject: AuthTokenRejecter) => {
  setTimeout(() => {
    // keep polling until we receive a response
    if (!_OAuthToken) {
      if (_authWindow) {
        _authWindow.postMessage('getOAuthToken', `*`);
      }

      // if the auth window is closed, stop polling
      if (_authWindow && !_authWindow.closed) {
        askOAuthServerForToken(OAuthURI, resolve, reject);
      } else if (_authWindow && _authWindow.closed) {
        _authWindow = null;
        reject(new Error('User cancelled OAuth flow'));
      } else {
        removeAuthWindowListener();
        cleanupAuthWindow();
      }
    }
  }, _POLL_TIME);
};

const login = async (OAuthURI: string) => {
  if (!OAuthURI) {
    throw new Error('Missing parameter: Need to include the backend OAuth2 URI');
  }

  if (_OAuthToken) {
    await logout();
  }

  window.location.replace(OAuthURI);
  return new Promise<AuthTokenResponse>((resolve, reject) => {
    _authWindowListener = createAuthWindowListener(OAuthURI, resolve, reject);
    window.addEventListener('message', _authWindowListener);
    askOAuthServerForToken(OAuthURI, resolve, reject);
  });
};

const logout = async () => {
  return new Promise<boolean>(resolve => {
    removeAuthWindowListener();
    cleanupAuthWindow();

    // TO DO: Make a backend call to destroy the token
    _OAuthToken = null;
    resolve(true);
  });
};

const handleImplicitRedirect = (hash = window.location.hash || '', opener: Window | null = window.opener) => {
  let OAuthToken: string | undefined, error: string | undefined, errorDescription: string | undefined;

  try {
    OAuthToken = (hash.match(/access_token=(.+)$/) as RegExpMatchArray)[1]?.split('&')[0];
  } catch (e) {
    /**/
  }

  if (!OAuthToken) {
    try {
      error = (hash.match(/error=(.+)$/) as RegExpMatchArray)[1]?.split('&')[0];
      errorDescription = (hash.match(/error_description=(.+)$/) as RegExpMatchArray)[1]?.split('&')[0];
    } catch (e) {
      /**/
    }
  }

  if (opener) {
    opener.postMessage(
      {
        OAuthToken,
        error,
        errorDescription,
      },
      window.origin
    );
  } else {
    return OAuthToken;
  }
};

const SESSION_TOKEN_KEY = 'tkn';
export const SESSION_TOKEN_PREVENT_STASH_VALUE = '__stop_stashes__';

// stash the current oauth session token in session storage during browser transitions
const stashTokenInSession = (value?: string) => {
  const checkValue = window.sessionStorage.getItem(SESSION_TOKEN_KEY);
  if (value !== SESSION_TOKEN_PREVENT_STASH_VALUE && value !== undefined && !!checkValue) {
    return;
  }
  if (!value) {
    const oauthToken = handleImplicitRedirect(window.location.hash, null);
    const port = window.location.port ? `:${window.location.port}` : '';
    window.sessionStorage.setItem(SESSION_TOKEN_KEY, oauthToken || '');
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}${port}${window.location.pathname}`
    );
  } else {
    window.sessionStorage.setItem(SESSION_TOKEN_KEY, value);
  }
};

// retrieve the stashed oauth session token in session storage (unless logging out) and then delete it
const fetchTokenFromSession = (): string | null => {
  const oauthToken = window.sessionStorage.getItem(SESSION_TOKEN_KEY);
  if (oauthToken === SESSION_TOKEN_PREVENT_STASH_VALUE) {
    return null;
  }
  window.sessionStorage.removeItem(SESSION_TOKEN_KEY);
  return oauthToken;
};

export { login, logout, handleImplicitRedirect, stashTokenInSession, fetchTokenFromSession };
export type { AuthTokenResponse };

import { createSlice } from '@reduxjs/toolkit';

export interface DialogData {
  open: boolean;
  component: string;
  props: any;
}

export interface IInitialState {
  flowCenterDialog: DialogData;
}

export interface FlowCenterDialogAction {
  payload: DialogData;
}

export const namespace = 'flowCenterDialog';

const initialState: IInitialState = {
  flowCenterDialog: {
    open: false,
    component: '',
    props: {},
  },
};

export const flowCenterDialogSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    flowCenterDialogAction(state: IInitialState, action: FlowCenterDialogAction) {
      state.flowCenterDialog = action.payload;
    },
  },
});

export const flowCenterDialogSelector = (state: IInitialState) => {
  return state?.[namespace] || null;
};

// Actions
export const { flowCenterDialogAction } = flowCenterDialogSlice.actions;

export const flowCenterDialogReducer = flowCenterDialogSlice.reducer;

import { Box, Typography, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { showReportDialog, lastEventId } from '@sentry/react';
import { useSelector } from 'react-redux';
import { userSelector } from '@aiware/shared/redux';

const SentryErrorFallback = ({ error }: { error: Error }) => {
  const user = useSelector(userSelector) as { preferredLanguage: string };

  const handleUserFeedback = async () => {
    try {
      const eventId = lastEventId();
      showReportDialog({
        eventId,
        lang: user?.preferredLanguage || 'en',
      });
    } catch (error) {
      console.error('Failed to capture event:', error);
    }
  };
  return (
    <Box>
      <Typography variant="h1">
        <FormattedMessage
          id="aiware-desktop-fallback-error-text"
          defaultMessage="Oops, something went wrong!"
          description="Error boundary message"
        />
      </Typography>
      <Typography variant="body2">{error.message}</Typography>
      <Button onClick={handleUserFeedback}>
        <FormattedMessage
          id="aiware-desktop-feedback-button-text"
          defaultMessage="Provide Feedback"
          description="User feedback button"
        />
      </Button>
    </Box>
  );
};

export default SentryErrorFallback;

import { logWithSentry } from './sentry';

/**
 * Base Graphql api for common usage.
 *
 * @param query
 * @param extraHeaders
 * @param variables
 * @param operationName
 * @param graphEndpoint
 * @param token
 */
export async function baseGraphQLApi<T>({
  query,
  extraHeaders,
  variables,
  operationName,
  graphEndpoint,
  token,
}: {
  query: string;
  extraHeaders?: T;
  variables?: Record<string, unknown>;
  operationName?: unknown;
  graphEndpoint: string;
  token?: string;
}) {
  const response = await fetch(graphEndpoint, {
    method: 'post',
    body: JSON.stringify({
      query,
      variables,
      operationName,
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
      ...extraHeaders,
    },
  });

  const resp = await response.json();

  if (resp.errors) {
    await logWithSentry(resp, {
      query,
      variables,
    });
  } else if (resp.data?.errors) {
    await logWithSentry(resp.data, {
      query,
      variables,
    });
  }

  return resp;
}

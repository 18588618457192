import { useEffect, useState } from 'react';

const useBackupImg = (src: string, backupSrc: string): [src: string, handleErrorCallback: () => void] => {
  const [imgSrc, setImgSrc] = useState(src || backupSrc);

  const handleError = () => setImgSrc(backupSrc);

  useEffect(() => {
    setImgSrc(src || backupSrc);
  }, [src, backupSrc]);

  return [imgSrc, handleError];
};

export default useBackupImg;

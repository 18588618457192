export const fetchEngineQuery = `
    query fetchEngine($engineId:ID!, $limit: Int){
        engine(id: $engineId){
            name
            industries
  					category{
    					name
  					}
            tasks {
              limit
              count
              records {
                name
                id
                jobId
                status
                createdDateTime
                queuedDateTime
                startedDateTime
                completedDateTime
              }
            }
            useCases
            deploymentModel
            validStateActions
            deployedVersion
            supportedInputFormats
            preferredInputFormat
            fields {
              info
              options {
                key
                value
              }
            }
            testingDetails {
              isCertified
            }
            isConductor
            iconPath
            edgeVersion
            deployedVersion
            categoryId
            description
            deploymentModel
            id
            state
            ownerOrganizationId
            ownerOrganization{
              name
              status
            }
            category {
                name
                iconClass
                class {
                  name
                  iconClass
                  id
                }
                color
                categoryType
                description
                searchConfiguration {
                  autocompleteFields {
                    autocompleteField
                  }
                  elasticType
                  searchFields {
                    searchField
                  }
                  searchMetadataKey
                }
            }
            createdDateTime
            modifiedDateTime
            runtimeType
            manifest
            builds(limit: $limit) {
            records {
                version
                id
                createdDateTime
                modifiedDateTime
                name
                status
                modifiedDateTime
                runtime
                primaryAction
              }
            }
        }
    }
`;

export const fetchEnginesQuery = `
  query fetchEngines($name: String, $offset: Int, $owned: Boolean) {
    engines(name: $name, offset: $offset, owned: $owned) {
      records {
        id
        displayName
        signedIconPath
        edgeVersion
        deployedVersion
        modifiedDateTime
        category {
          name
        }
      }
    }
  }
`;

export const deleteEngineQuery = `
  mutation ($id: ID!) {
    deleteEngine(id: $id){
      id
    }
  }
`;

export const fetchApplicationsQuery = `
  {
    applications {
      records {
        id
        name
        iconUrl
        description
        url
        modifiedDateTime
        category
      }
    }
  }
`;

export const fetchApplicationByIdQuery = `
  query applications($appId: ID!){
    applications(id: $appId){
      records{
        name
        description
        status
        id
        category
        iconUrl
        modifiedDateTime
        organizationId
      }
    }
  }
`;

export const fetchOrganizationNameQuery = `
  query applications($organizationId: ID!){
    organization(id: $organizationId){
      name
    }
  }
`;

export const fetchTasksQuery = `
  query fetchTasks($engineId: [ID!], $limit: Int, $offset: Int) {
    tasks(engineId: $engineId, limit: $limit, offset: $offset){
      count
      records{
        id
        status
        buildId
        applicationId
        createdDateTime
        startedDateTime
        queuedDateTime
        completedDateTime
        jobId
        job {
          id
          description
          status
        }
      }
    }
  }
`;

export const getRoutesQuery = `
    query getRoutes($jobId:ID!){
        job(id: $jobId){
        routes{
        endpoint
        }
    }
    }
`;

export const getSignedUrlQuery = `
    query getSignedWritableUrl($name: String!){
        getSignedWritableUrl(key: $name) {
            url
            key
            bucket
            expiresInSeconds
            getUrl
            unsignedUrl
        }
    }
`;

export const createSingleJobQuery = `
    mutation(
        $uploadUrl: String,
        $engineId: ID!,
        $fields: [EngineFieldValue]
    ) {
        launchSingleEngineJob(input: {
            uploadUrl: $uploadUrl
            engineId : $engineId
            fields: $fields
        }) {
            id
            status
            tasks {
                records {
                    id
                    status
                    engine {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const getJobStatusQuery = `
    query getJobStatus($jobId: ID!){
        job(id: $jobId){
            status,
            targetId
        }
    }
`;

export const engineResultQuery = `
    query engineResults($tdoId: ID){
        engineResults(tdoId: $tdoId){
            records{
                tdoId
                engineId
                libraryId
                jsondata
                assetId
            }
        }
    }
`;

export const sendMailMutation = `mutation (
  $email: String!,
  $subject: String!,
  $message: String!
) {
  sendEmail(input: {
    from: "dev@veritone.com"
    to: $email
    subject: $subject
    message: $message
    replyTo: "dev@veritone.com"
  })
}`;

export const fetchTemplateGalleryQuery = `query($isPublic: Boolean, $categories: [String], $tags: [String], $developers: [String], $limit: Int, $offset: Int) {
  flowTemplates(showPublic: $isPublic, categories: $categories, tags: $tags, authors: $developers, limit: $limit, offset: $offset){
    records {
      id
      title
      author
      subtitle
      tags
      description
      modified
      learnmorelink
      image
      logo
      categories
      public
    }
  }
}`;

export const getTemplateByIdQuery = `
  query($flowId: ID!) {
    flowTemplates(id: $flowId) {
      records {
        id
        title
        subtitle
        description
        categories
        flow
        package
        image
        screenshots
        tags
        author
        public
        learnmorelink
        logo
      }
    }
  }
`;

//templates fallback
export const getTemplateByIdQueryFallback = `
  query($flowId: ID!) {
    flows(id: $flowId) {
      records {
        id
        title
        subtitle
        description
        categories
        flow
        package
        image
        screenshots
        tags
        author
        public
        learnmorelink
        logo
      }
    }
  }
`;

export const createEngineMutation = `mutation($engineJson: CreateEngine!) {
  createEngine(input: $engineJson) {
   id
   name
   createdDateTime
   manifest
  }
}
`;

export const createEngineBuildMutation = `mutation($engineId:ID!, $manifest: JSONData, $taskRuntime: JSONData, $dockerImage: String, $JWTRights:JWTRightsField){
  createEngineBuild(input:{
    engineId: $engineId
    manifest: $manifest
    taskRuntime: $taskRuntime
    dockerImage: $dockerImage
  }){
    engineId
    id
  }
  updateEngine(input: {
    id: $engineId
    jwtRights: $JWTRights
  }){
    name
    id
    jwtRights
  }
}
`;

const automationId = 'c5458876-43d2-41e8-a340-f734702df04a';

export const fetchFlowsQuery = `
  query fetchFlows($name: String, $offset: Int, $owned: Boolean, $limit: Int, $manifestRuntimeType: [String]) {
    engines(name: $name, offset: $offset, owned: $owned, limit: $limit, categoryId: "${automationId}", filter: {manifestRuntime: $manifestRuntimeType}) {
      offset
      count
      limit
      records {
        id
        name
        state
        ownerOrganization {
          id
          name
        }
        manifest
        createdDateTime
        modifiedDateTime
        builds {
          records{
            id
            version
          }
        }
      }
    }
  }
`;

export const fetchFlowsQueryNoRuntimeType = `
  query fetchFlows($name: String, $offset: Int, $owned: Boolean, $limit: Int) {
    engines(name: $name, offset: $offset, owned: $owned, limit: $limit, categoryId: "${automationId}") {
      offset
      count
      limit
      records {
        id
        name
        state
        ownerOrganization {
          id
          name
        }
        manifest
        createdDateTime
        modifiedDateTime
        builds {
          records{
            id
            version
          }
        }
      }
    }
  }
`;

export const fetchFlowsIdsQuery = `
  query {
    flows {
      records {
        id
      }
    }
  }
`;

export const fetchTagByFlowIdQuery = `
  query fetchTagByFlowId ($id: ID!) {
    flows(id: $id) {
      records {
        tags
      }
    }
  }
`;
export const scheduledJobsQuery = `
  query getScheduledjobs($engineId: ID, $clusterId: ID){
    scheduledJobs(
      limit: 100
      engineId: $engineId
      clusterId: $clusterId
    ){
      limit
      records{
        id
        isActive
        jobs {
          records {
            name
            status
            createdDateTime
            createdBy
            id
            tasks {
              records {
                status
                name
                engineId
                engine {
                  name
                }
                buildId
              }
            }
          }
        }
        parts {
          startTime
          stopTime
          repeatInterval
          repeatIntervalUnit
          scheduleType
          scheduledDay
          scheduledDayLocal
          durationSeconds
        }
        startDateTime
        stopDateTime
        jobTemplates {
          records {
            id
            clusterId
            routes {
              parentIoFolderReferenceId
            }
          }
        }
        primarySource {
          name
          state
          sourceType {
            name
            category {
              name
            }
          }
          details
          createdDateTime
          isPublic
        }
        collaborators {
          records {
            organization {
              name
            }
            organizationId
          }
        }
        affiliates {
          records {
            status
          }
        }
        allJobTemplates {
          records {
            createdDateTime
          }
        }
        createdDateTime
        ingestionStatus
        name
        runMode
        details
      }
    }
  }`;

export const getGeneratedFetchTagsQueryFromListIds = (ids: [number]) => {
  const firstId = ids[0];
  const firstTagsQuery = `
    tags_0: flows (id: ${firstId}) {
      records {
        id
        tags
      }
    }
  `;
  return `
  query fetchTags {
    ${firstTagsQuery}
    ${ids.map((item, index) =>
      index !== 0
        ? `
    , tags_${index}: flows (id: ${item}) {
      records {
        id
        tags
      }
    }
    `
        : ``
    )}
  }`;
};
export const createJobTemplateQuery = `mutation createJobTemplate($engineId: ID,$cluster: ID, $uid1: String, $uid2: ID!, $uid3: ID!) {
  createJobTemplate(input: {
    clusterId: $cluster
    ##Tasks with IOFolders
    taskTemplates: [
      {
        engineId: $engineId
        ioFolders: [
          {
            referenceId: $uid2
            mode: chunk
            type: output
          }
        ]
        executionPreferences:
            {
              priority: -99
            }
      }
      {
        # User engine
        engineId: $engineId
        ioFolders: [
          {
            referenceId: $uid3
            mode: chunk
            type: input
          }
        ]
      }
    ]
    ##Routes : A route connect a parent output folder to a child input folder
    routes: [
      {
        endpoint: $uid1
        parentIoFolderReferenceId: $uid2
        childIoFolderReferenceId: $uid3
        options: {}
      }
    ]
  }) {
    id
    createdDateTime
  }
}`;

export const runJobTemplateQuery = `mutation createScheduledJob(
  $jobTemplateIds: [ID!],
  $startDateTime: DateTime,
  $stopDateTime: DateTime,
  $runMode: RunMode,
  $name: String!,
){
createScheduledJob(input:{
  name: $name
  runMode: $runMode
  recurringScheduleParts: []
  isActive: true
  startDateTime: $startDateTime
  stopDateTime: $stopDateTime
  jobTemplateIds: $jobTemplateIds
}){
  id
  jobs{
    records{
      id
      name
    }
  }
  jobTemplates{
    count
    records{
      id
      jobPipelineStage
      notificationUris
    }
  }
  name
  details
  isActive
  organization{
    name
  }
 collaborators {
  count
}
  createdDateTime
}
} `;

export const createJobQuery = `mutation createJob($engineId: ID,$cluster: ID, $uid1: String, $uid2: ID!, $uid3: ID!) {
  createJob(input: {
    clusterId :$cluster
    tasks: [
      {
        engineId: $engineId
        ioFolders: [
          {
            referenceId: $uid2
            mode: chunk
            type: output
          }
        ]
      }
      {
        engineId: $engineId
        ioFolders: [
          {
            referenceId: $uid3
            mode: chunk
            type: input
          }
        ]
      }
    ]
    routes: [
      {
        endpoint: $uid1
        parentIoFolderReferenceId: $uid2
        childIoFolderReferenceId: $uid3
        options: {}
      }
    ]
    }){
      id
    }
  }`;

export const isRevisionsExistOnServerQuery = `
  query getFlowRevisions {
    flowRevisions {
      records {
        flowRevisionId
        userId
      }
    }
  }
`;

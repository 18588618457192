import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUniqueID } from '@aiware/js/function';
import {
  IGenericState,
  IMicroFrontend,
  IPanel,
  ISelfClosingPanelConfig,
  IShowPanelProps,
} from '@aiware/js/interfaces';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { IModuleStore } from 'redux-dynamic-modules-core';

export const PANELS_FEATURE_KEY = 'panels';

export const panelsSelector = (state: IGenericState) => state[name] as IPanel<unknown, unknown>[];

export const selectPanelByPanelName = (panelId: string) => {
  return (state: IGenericState) =>
    (state[PANELS_FEATURE_KEY] as []).find(
      (panel: IPanel<{ panelConfig: { panelId: string } }, any>) =>
        panel?.panelConfig?.panelId === panelId || panel?.panelId === panelId
    );
};

/**
 * Panels Slice
 */
export const {
  name,
  reducer,
  actions: {
    mountPanel,
    renderPanel,
    unmountPanel,
    hidePanel,
    updatePanelMicroFrontendProps,
    openLinkOnNewTab,
    unmountAllPanel,
    updatePanelConfig,
  },
} = createSlice({
  name: PANELS_FEATURE_KEY,
  initialState: [] as IPanel<unknown, unknown>[],
  reducers: {
    // add widget to store when mounted
    mountPanel: (state, action: PayloadAction<IPanel<unknown, unknown>>) => {
      state.push(action.payload);
      return state;
    },
    renderPanel: (state, action: PayloadAction<IPanel<unknown, unknown>>) => {
      return state;
    },
    updatePanelConfig: (state, action: PayloadAction<{ panelId: string; panelConfig: unknown }>) => {
      const panelIndex = state.findIndex(panel => panel.panelId === action.payload.panelId);
      if (panelIndex >= 0) {
        const currentPanel = state[panelIndex];
        state[panelIndex] = {
          ...currentPanel,
          panelConfig: action.payload.panelConfig,
          panelId: action.payload.panelId,
          microFrontend: currentPanel?.microFrontend as IMicroFrontend<any>, // Ensuring microFrontend is preserved if not specified
        };
      }
      return [...state]; // force update
    },
    // remove widget from store when unmounted
    unmountPanel: (state, action: PayloadAction<string>) => {
      return state.filter(item => item.panelId !== action.payload);
    },
    hidePanel: (state, _action: PayloadAction<string>) => {
      // const item = state.find((item) => item.panelId === action.payload);
      // item.panelConfig.show = false;
      return state;
    },
    updatePanelMicroFrontendProps: (state, _action: PayloadAction<{ id: string; props: unknown }>) => {
      return state;
    },
    openLinkOnNewTab: (state, _action?: PayloadAction<{ url: string }>) => {
      return state;
    },
    unmountAllPanel: (state, _action: PayloadAction<unknown>) => {
      return state;
    },
  },
});

function* handleClosePanel(panelId: string, disableHide: boolean | undefined, removeModule: () => void) {
  // hide the panel by default
  if (disableHide) {
    yield put(unmountPanel(panelId));
  } else {
    yield put(hidePanel(panelId));
  }

  setTimeout(() => {
    removeModule();
  }, 0);
}

/***
 * mounts a panel that closes itself and resolves the returned promise when the response for the specified panel is ready
 * @param props
 * @param store
 */
export const mountPanelForResponse = <T = unknown, U = unknown>(
  props: IShowPanelProps<T, ISelfClosingPanelConfig>,
  store: IModuleStore<unknown>
): Promise<U> => {
  const panelId: string = props.panelId || getUniqueID();
  const { microFrontend, panelConfig } = props;

  store.dispatch(mountPanel({ microFrontend, panelId, panelConfig }));

  let deferredResolve: (data: U) => void;
  let deferredReject: (reason: unknown) => void;
  let removeModule: () => void = () => null;
  const result = new Promise<U>((resolve, reject) => {
    deferredResolve = resolve;
    deferredReject = reject;
  });

  const module: ISagaModule<unknown> = {
    id: panelId,
    sagas: [
      function* () {
        yield panelId;
        yield takeEvery(`${microFrontend.name}_RESPONSE`, function* (action: PayloadAction<U>) {
          yield action.payload;
          deferredResolve(action.payload);
          yield call(handleClosePanel, panelId, props.panelConfig.disableHide, removeModule);
        });
        yield takeEvery(
          `${microFrontend.name}_ERROR_RESPONSE`,
          function* (action: PayloadAction<{ error: unknown; closePanel: boolean }>) {
            const { error, closePanel } = action.payload;
            yield error;
            deferredReject(error);
            if (closePanel) {
              yield call(handleClosePanel, panelId, props.panelConfig.disableHide, removeModule);
            }
          }
        );
      },
    ],
  };
  removeModule = store.addModule(module).remove;
  return result;
};

import { createAction } from '@reduxjs/toolkit';

export const appSwitcherSetupListener = createAction(
  `aiware/set up app switch listener`
);

export const appSwitcherHandleClick = createAction<string>(
  `aiware/handle app switch`
);

export const appSwitcherHandleClickAppNatively = createAction<string>(
  `aiware/applications/get handle click app`
);

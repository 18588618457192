import AiwareSDKHelpers from '@aiware/js/sdk-helpers';
import { SdkEvents, SdkEventCallback, ISdkEventManager, ISdkError } from '@aiware/js/interfaces';

export enum PermissionChangedAction {
  GRANTED = 'granted',
  REVOKED = 'revoked',
}

export class SdkEventsManager extends AiwareSDKHelpers implements ISdkEventManager {
  events = new Map();

  /**
   * Registers events into the `events` object
   * so we can retrieve later.
   *
   * @param {SdkEvents} event
   * @param {SdkEventCallback} cb
   */
  on(event: SdkEvents, cb: SdkEventCallback) {
    if (!SdkEvents[event]) {
      throw new SdkError(`Unknown event type: \`${event}\``);
    }

    this.events.set(event, function (error?: ISdkError | null, ...args: unknown[]) {
      cb(error, ...args);
    });
  }

  /**
   * Deletes the registered event from the `events` object.
   *
   * @param {SdkEvents} event
   */
  off(event: SdkEvents) {
    this.events.delete(event);
  }

  /**
   * Runs registered event with provided arguments.
   *
   * @param {SdkEvents} event
   * @param {ISdkError | null} error
   * @param {unknown[]} args
   */
  //@ts-ignore
  dispatch(event: SdkEvents, error?: ISdkError | null, ...args: unknown[]) {
    if (!this.events.has(event)) {
      return;
    }

    this.events.get(event)(error, ...args);
  }

  createError(message: string) {
    return new SdkError(message) as ISdkError;
  }
}

export class SdkError extends Error implements ISdkError {
  type = 'error';

  constructor(message: string, type: 'error' | 'abort' = 'error') {
    super(`[AiwareJS SDK error]: ${message}`);
    this.type = type;
  }
}

export const sdkEventManager = new SdkEventsManager() as ISdkEventManager;

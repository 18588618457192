import { baseGraphQLApi } from './baseGraphQLApi';

export const postNotificationApi = async (
  graphEndpoint: string,
  userId: string,
  command: unknown = {},
  ephemeral = false,
  contentType: 'text' | 'json' = 'text',
  eventType: string,
  token?: string
): Promise<unknown> => {
  const query = `
  mutation ($body: String!, $userId: ID!, $ephemeral: Boolean!, $contentType: String!, $eventType: String){
    notificationPost(
      input: {
        mailboxIds: [$userId],
        title:"System Notification",
        body: $body
        contentType: $contentType
        flags: [unread, unseen]
        ephemeral: $ephemeral
        eventType: $eventType
      }
      ) {
        id
        body
        contentType
        createdDateTime
      }
    }`;

  const variables = {
    body: JSON.stringify(command),
    userId,
    ephemeral,
    contentType,
    eventType,
  };

  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token } as any);
  } catch (error) {
    return {
      success: false,
    };
  }
};

import md5 from 'md5';
import { baseGraphQLApi } from '../api/baseGraphQLApi';
import isEqual from 'lodash/isEqual';
import { GraphQLError } from 'graphql';
import * as Sentry from '@sentry/react';

type TNestedType = {
  name: string | null;
  kind: string | null;
  ofType: {
    name: string | null;
    kind: string | null;
    ofType: {
      name: string | null;
      kind: string | null;
      ofType: {
        name: string | null;
        kind: string | null;
      };
    };
  };
};

type TField = {
  name: string;
  args: TField[];
  type: TNestedType;
};

export type TFlattenField = {
  name: string;
  type: string | null;
  args: { [key: string]: string }[];
};

export type TGqlOperation = {
  name: string;
  args?: TField[];
  fields?: TField[];
  type?: TNestedType;
};

type TTypeFromServer = {
  name: string;
  fields: TField[];
};

type TTypeInMemory = {
  name: string;
  fields: TFlattenField[];
};

type TOpType = 'query' | 'mutation';

type TEnumValuesResponse = { data: { __type: { enumValues: { name: string | number }[] } } };

class GqlIntrospection {
  initialized: boolean;
  query: TGqlOperation[];
  mutation: TGqlOperation[];
  types: TTypeInMemory[];
  introspectionEnabled: boolean;
  hashMap: { [key: string]: string };
  alertDevs: boolean;
  params: { [key: string]: string | boolean }[];
  safety: boolean;
  enums: { [key: string]: (string | number)[] };

  constructor(safety = false) {
    this.initialized = false;
    this.query = [];
    this.mutation = [];
    this.types = [];
    this.introspectionEnabled = true;
    this.hashMap = {};
    this.alertDevs = false;
    this.params = [];
    this.safety = safety; // use for throwing when not found or returning original query
    this.enums = {};
  }

  public async initialize(graphEndpoint: string, token: string, alertDevs: boolean, safety = false) {
    this.alertDevs = alertDevs;
    this.safety = safety;
    if (!this.initialized) {
      this.initialized = true;
      const query = `{
        __schema {
          queryType {
            fields {
              name
              type {
                name
                kind
                ofType {
                  name
                  kind
                  ofType {
                    name
                    kind
                    ofType {
                      name
                      kind
                    }
                  }
                }
              }
              args {
                name
                type {
                  name
                  kind
                  ofType {
                    name
                    kind
                    ofType {
                      name
                      kind
                      ofType {
                        name
                        kind
                      }
                    }
                  }
                }
              }
            }
          }
          mutationType {
            fields {
              name
              type {
                name
                kind
                ofType {
                  name
                  kind
                  ofType {
                    name
                    kind
                    ofType {
                      name
                    }
                  }
                }
              }
              args {
                name
                type {
                  name
                  kind
                  ofType {
                    name
                    kind
                    ofType {
                      name
                      kind
                      ofType {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`;
      const response = await baseGraphQLApi({ query, graphEndpoint, token });
      if (response.errors) {
        // in case introspection is disabled - skip args/fields validation
        this.introspectionEnabled = false;
        return;
      }
      this.mutation = (response?.data?.__schema?.mutationType?.fields || []).map((field: TGqlOperation) => {
        const fieldType = this.typeBuilder(field.type);
        return {
          name: field.name,
          type: fieldType,
          args: field.args?.map((arg: TField) => {
            const type = this.typeBuilder(arg.type);

            // alert developers if any of the mutations have even more deeply nested arg type names
            if (isEqual(process.env['NODE_ENV'], 'development') && !type && this.alertDevs) {
              alert(
                `${field.name} mutation is missing ${arg.name} argument type. Check if it's nested one more level deep`
              );
            }
            if (isEqual(process.env['NODE_ENV'], 'development') && !fieldType && this.alertDevs) {
              alert(`${field.name} query is missing its type. Check if it's nested one more level deep`);
            }
            return {
              name: arg.name,
              type,
            };
          }),
        };
      });
      this.query = (response?.data?.__schema?.queryType?.fields || []).map((field: TGqlOperation) => {
        const fieldType = this.typeBuilder(field.type);
        const args = field.args?.map((arg: TField) => {
          const type = this.typeBuilder(arg.type);

          // alert developers if any of the queries have even more deeply nested arg type names
          if (isEqual(process.env['NODE_ENV'], 'development') && !type && this.alertDevs) {
            alert(
              `${field.name} query is missing ${arg.name} argument type. Check if it's nested one more level deep`
            );
          }
          if (isEqual(process.env['NODE_ENV'], 'development') && !fieldType && this.alertDevs) {
            alert(`${field.name} query is missing its type. Check if it's nested one more level deep`);
          }
          return {
            name: arg.name,
            type,
          };
        });
        return {
          name: field.name,
          type: fieldType,
          args,
        };
      });
    }
  }

  public async getEnumValues(graphEndpoint: string, token: string, typeName: string) {
    if (this.enums[typeName]) return this.enums[typeName];
    try {
      const query = `{
        __type(name: "${typeName}") {
          enumValues {
            name
          }
        }
      }`;
      const response: TEnumValuesResponse = await baseGraphQLApi({
        query,
        graphEndpoint,
        token,
      });
      const values = response.data.__type.enumValues.map(enumValue => enumValue.name);
      this.enums[typeName] = values;
      return values;
    } catch (err) {
      throw new Error('Introspection call failed: Enum values cannot be fetched');
    }
  }

  public async getAvailableFields(
    graphEndpoint: string,
    token: string,
    typeName: string,
    fieldsToCheck?: string[]
  ) {
    const typeToCheck = typeName.replace(/[\[\]!]+/g, '');
    if (this.introspectionEnabled) {
      // check if the type is already in memory
      let typeInMemory: TTypeInMemory | TTypeFromServer | undefined = this.types.find(
        type => type.name === typeToCheck
      );
      if (!typeInMemory) {
        // otherwise make a server call
        const query = `{
          __type(name: "${typeToCheck}") {
            name
            fields {
              name
              args {
                name
                type {
                  name
                  kind
                  ofType {
                    name
                    kind
                    ofType {
                      name
                      kind
                      ofType {
                        name
                        kind
                      }
                    }
                  }
                }
              }
              type {
                name
                kind
                ofType {
                  name
                  kind
                  ofType {
                    name
                    kind
                    ofType {
                      name
                      kind
                    }
                  }
                }
              }
            }
          }
        }`;
        const response = await baseGraphQLApi({ query, graphEndpoint, token });
        if (!response.errors) {
          typeInMemory = response?.data?.__type as TTypeFromServer;
          if (typeInMemory) {
            const { name } = typeInMemory;
            // flatten response to have "name" and "type" on the same root level of a field
            const flattenedType: TTypeInMemory = { name, fields: [] };
            flattenedType.fields = (typeInMemory.fields || []).map(field => ({
              name: field.name,
              args: field.args.map(arg => ({
                name: arg.name,
                type: this.typeBuilder(arg.type),
              })),
              type: this.typeBuilder(field?.type),
            }));
            // add fetched type to memory
            this.types.push(flattenedType);
            typeInMemory = flattenedType;
          }
        }
      }
      // return available fields
      if (fieldsToCheck) {
        // parse and return available ones
        const availableFields: TFlattenField[] = [];
        typeInMemory?.fields.forEach(availabileField => {
          if (fieldsToCheck.find((fieldToCheck: string) => availabileField.name === fieldToCheck)) {
            availableFields.push(availabileField);
          }
        });
        return availableFields;
      }
      // otherwise return complete list of available fields
      return typeInMemory?.fields;
    }
  }

  // "any" below is needed to support deep nesting of the types where we don't know at
  // what level of nesting the type "name" will be found
  private typeBuilder(type: any) {
    if (type.name) return type.name;
    let correctType = '';
    if (type.kind) {
      if (type.kind === 'NON_NULL') {
        correctType = this.typeBuilder(type.ofType) + '!';
      }
      if (type.kind === 'LIST') {
        correctType = '[' + this.typeBuilder(type.ofType) + ']';
      }
    }
    return correctType;
  }

  public getQueries() {
    return this.query;
  }

  public getMutations() {
    return this.mutation;
  }

  public getTypes() {
    return this.types;
  }

  public isOperationValid(opName: string, opType?: TOpType): boolean {
    // passing opType makes it much more efficient
    if (opType) {
      return !!this[opType].find(op => op.name === opName);
    }
    return !!this.query.find(op => op.name === opName) || !!this.mutation.find(op => op.name === opName);
  }

  private indentationChecker(opLine: string) {
    let opIndentation = 0;
    let charIndex = 0;
    // check for indentation length until we hit the operation name
    while (opLine[charIndex] === ' ') {
      opIndentation += 1;
      charIndex += 1;
    }
    return opIndentation;
  }

  private nestingChecker(storage: string[]) {
    let i = 0;

    while (i < storage.length) {
      if (
        (storage as any)[i][(storage as any)[i].length - 1] === '{' &&
        storage[i + 1] &&
        (storage as any)[i + 1][(storage as any)[i + 1].length - 1] === '}'
      ) {
        storage.splice(i, 2);
      } else {
        i += 1;
      }
    }
    if (storage.length === 2) {
      throw new Error('Provided operation payload is not supported');
    }
    return storage;
  }

  private async recursiveFieldCheck(
    lines: string[],
    type: string | null,
    graphEndpoint: string,
    token: string,
    storage: string[] = [],
    params: string | { [key: string]: string | boolean }[],
    line = 1,
    level = 2
  ) {
    let indentation = '  ';
    let returnIndentation = '';
    let stackLevel = level;
    while (stackLevel - 1) {
      indentation += ' ';
      returnIndentation += ' ';
      stackLevel -= 1;
    }
    const typeFoundInMemory = this.types.find((typeInMemory: TTypeInMemory) => typeInMemory.name === type);
    // when type is not in memory - fetch from the server and store in memory
    if (!typeFoundInMemory && type) {
      await this.getAvailableFields(graphEndpoint, token, type);
    }

    let i = line;
    while (i < lines.length) {
      let field: string;

      // handle fragment
      const isFragment = lines[i]?.includes('...');
      if (isFragment) {
        field = (lines as any)[i].split('on')[1].split('{')[0].trim() as any;
        // make sure current type from the fragmented field is in memory
        await this.getAvailableFields(graphEndpoint, token, field);
      } else {
        // handle spaceless cases like "someField(arg:$arg){"
        field = (lines as any)[i].trim().split('(')[0].trim().split('{')[0].trim();
      }

      // check if field has an alias assigned
      let aliasedField: string;
      if (field.includes(':')) {
        aliasedField = field.split(':')[1] as any;
      } else {
        aliasedField = field;
      }

      // field availability validation
      const currentType = this.types.find(storedType => {
        if (isFragment) return storedType.name === field;
        return storedType.name === (type || '').replace(/[\[\]!]+/g, ''); // this is to remove [] or ! from the type definition
      });

      if (currentType || field === '}' || isFragment) {
        // check if field exists on the current type
        const isValidField = isFragment
          ? currentType
          : currentType?.fields.find(typeField => typeField.name === aliasedField);

        if (isValidField || field === '}') {
          // check if nested field has any arguments
          let fieldParams = '';
          if ((lines as any)[i].includes('(') && isValidField) {
            const operationParameters = this.getParameters(lines, i);
            const assembledParams = this.paramsParser(
              operationParameters,
              params as { [key: string]: string }[]
            );

            // validate nested field params
            if (assembledParams.length) {
              const validatedArgs: { [key: string]: string }[] = [];
              const fieldArgs = currentType?.fields.find(typeField => typeField.name === field)?.args || [];
              assembledParams.forEach(assembledParam => {
                const validArg = fieldArgs.find(
                  arg =>
                    (arg as any)['name'] in assembledParam &&
                    assembledParam[(arg as any)['name']] === arg['type']
                );
                if (validArg) {
                  const paramIndex = this.params.findIndex(
                    param => (assembledParam as any)['field'] in param
                  );
                  if (paramIndex !== -1) {
                    (this as any).params[paramIndex].validated = true;
                  }
                  validatedArgs.push({ ...validArg, field: assembledParam.field as any });
                }
              });

              if (validatedArgs.length) {
                // assemble a string
                fieldParams += '(';
                validatedArgs.forEach(
                  validatedArg =>
                    (fieldParams += `${(validatedArg as any)['name']}: ${(validatedArg as any)['field']} `)
                );
                fieldParams += ')';
              }
            }
          }

          // check if field is of a type with nested sub-fields
          if (((lines as any)[i].includes('{') && isValidField) || isFragment) {
            if (isFragment) {
              storage.push(`${indentation}... on ${field} ${fieldParams}{`);
            } else {
              storage.push(`${indentation}${field} ${fieldParams}{`);
            }
            const fieldType = isFragment ? field : (isValidField as TFlattenField)?.type || null;
            // if fieldType is undefined - the field might not exist
            i = (await this.recursiveFieldCheck(
              lines,
              fieldType,
              graphEndpoint,
              token,
              storage,
              params,
              i + 1,
              level + 1
            )) as number;
          } else if ((lines as any)[i].includes('}')) {
            storage.push(`${returnIndentation}}`);
            if (i === lines.length - 1) {
              return this.nestingChecker(storage).join('\n');
            }
            return i;
          } else {
            storage.push(`${indentation}${field}`);
          }
        } else if ((lines as any)[i].includes('{')) {
          // handle case when field is invalid and might contain nested sub-fields that have to be discarded
          let openingBraces = 1;
          while (openingBraces) {
            i += 1;
            if ((lines as any)[i].includes('{')) {
              openingBraces += 1;
            }
            if ((lines as any)[i].includes('}')) {
              openingBraces -= 1;
            }
          }
        }
      }
      i += 1;
    }
  }

  private getParameters(lines: string[], i: number) {
    return (lines as any)[i].split('(')[1].split(')')[0].trim().replace(/:\s/g, ':');
  }

  private paramsParser(string: string, variables: { [key: string]: string }[]) {
    const params = string.split(':');
    const trimmed: string[] = [];
    params.forEach(param => {
      const trimmedParam = param.trim();
      let childParams;
      if (trimmedParam.includes(' ')) {
        childParams = trimmedParam.split(' ');
      }
      if (trimmedParam.includes(',')) {
        childParams = trimmedParam.split(',');
      }
      if (childParams) {
        childParams.forEach(childParam => trimmed.push(childParam.trim()));
      } else {
        trimmed.push(trimmedParam);
      }
    });
    const compiledParams = [];
    for (let i = 0; i < trimmed.length - 1; i++) {
      const paramObject = variables.find(variable => (trimmed as any)[i + 1] in variable);
      if (paramObject) {
        const param = {
          [(trimmed as any)[i]]: paramObject[(trimmed as any)[i + 1]],
          field: trimmed[i + 1],
          validated: false,
        };
        compiledParams.push(param);
      }
      i += 1;
    }
    return compiledParams;
  }

  public async queryParser(
    graphEndpoint: string,
    token: string,
    query: string,
    throwIfNoValidArgs = true /** DO NOT USE FOR MUTATIONS PARSING
     if 'false' is passed then you assume the risk of having the introspection utility return a parsed query/mutaion that has no input arguments.
     This would be a survivable scenario for *some* queries, but a guaranteed failure for mutations
     */
  ) {
    this.params = [];
    try {
      if (!this.initialized) {
        await this.initialize(graphEndpoint, token, false);
      }
      // get the operation type
      const lines = query.split('\n');
      let operationType: string;
      let operationName = '';
      let params: string | string[] | { [key: string]: string | boolean }[] = '';
      if ((lines as any)[0].includes('(')) {
        // parametrized operation
        operationType = (lines as any)[0].split('(')[0].trim();
        if (operationType.split(' ').length > 1) {
          [operationType, operationName] = operationType.split(' ') as any;
        }
        params = this.getParameters(lines, 0);

        // case: $name:String,$id:ID
        if ((params as string).includes(',')) {
          params = (params as string).split(',').join('');
        }
        // case" $name:String$id:ID
        params = (params as string).split('$');
        params = params.filter(param => !!param);
        params.forEach(param => (param = `$${param.trim()}`));

        this.params = params.map(param => {
          const [key, value] = param.split(':');
          return {
            [`$${key}`]: (value as string).trim(),
            validated: false,
          };
        });
      } else {
        operationType = (lines as any)[0].split('{')[0].trim();
      }
      // if operation is named it can be something like "query users"
      if (operationType.includes(' ')) {
        [operationType, operationName] = operationType.split(' ') as any;
      }
      if (!operationType) {
        operationType = 'query';
      }

      // get indentation length for cases with multiple nested queries
      const opLine = lines[1];

      const opIndentation = this.indentationChecker(opLine as any);

      // check for lines with the same indentation, store operations and their indexes
      const operations = [];
      for (let i = 1; i < lines.length; i++) {
        let operationArgs = '';
        if (opIndentation === this.indentationChecker((lines as any)[i])) {
          let operation: string;
          let operationParameters: string | string[];
          // check if operation is parametrized
          let assembledParams;
          if ((lines as any)[i].includes('(')) {
            operation = (lines as any)[i].split('(')[0].trim();
            // parse arguments
            operationParameters = (lines as any)[i].split('(')[1].split(')')[0].trim();
            assembledParams = this.paramsParser(
              operationParameters as any,
              this.params as { [key: string]: string }[]
            );
          } else {
            operation = (lines as any)[i].split('{')[0].trim();
          }
          if (operation !== '}') {
            const operationInMemory = (this as any)[operationType].find(
              (storedOperation: TGqlOperation) => storedOperation.name === operation
            );

            // validate arguments
            if (assembledParams) {
              const validatedArgs: { [key: string]: string }[] = [];
              assembledParams.forEach(assembledParam => {
                const validArg = operationInMemory.args.find(
                  (arg: TFlattenField) =>
                    arg.name in assembledParam && assembledParam[(arg as any)['name']] === arg.type
                );
                if (validArg) {
                  const paramIndex = this.params.findIndex(
                    param => (assembledParam as any)['field'] in param
                  );
                  if (paramIndex !== -1) {
                    (this as any).params[paramIndex].validated = true;
                  }
                  validatedArgs.push({ ...validArg, field: assembledParam.field });
                }
              });
              if (validatedArgs.length) {
                // assemble a string
                operationArgs += '(';
                validatedArgs.forEach(
                  // eslint-disable-next-line no-loop-func
                  validatedArg => (operationArgs += `${validatedArg['name']}: ${validatedArg['field']} `)
                );
                operationArgs += ')';
              }
            }
            operations.push({ operation, line: i, type: operationInMemory?.type, args: operationArgs });
          }
        }
      }
      let mergedPayload = '';
      // loop through payload's operations, construct safe payloads for each
      for (let op = 0; op < operations.length; op++) {
        const lineCap = (operations[op + 1] || {}).line || lines.length - 1;
        const opType = (operations as any)[op].type;

        const opLines = lines.slice((operations as any)[op].line, lineCap);
        const hash = md5(opLines.join('').replace(/\s/g, ''));
        let validatedOperation: string | number | undefined;
        // check if this query has been validated - return value stored in memory
        if (this.hashMap[hash]) {
          isEqual(process.env['NODE_ENV'], 'development') &&
            console.log(`returning ${(operations as any)[op].operation} from memory`);
          validatedOperation = this.hashMap[hash];
        } else {
          // recursively descend to nested fields, fetch their types and validate desired response fields
          validatedOperation = await this.recursiveFieldCheck(
            opLines,
            opType,
            graphEndpoint,
            token,
            [`  ${(operations as any)[op].operation} ${(operations as any)[op].args} {`],
            this.params
          );
        }
        if (typeof validatedOperation !== 'string') {
          throw new Error('Introspection utility failed while validating operation');
        }

        // create a hash for each nested operation
        this.hashMap[hash] = validatedOperation as string;
        mergedPayload += '\n' + validatedOperation + '\n';
      }
      if (mergedPayload) {
        let safeParams = '';
        this.params.forEach(param => {
          if (param['validated']) {
            const [key, val] = Object.entries(param)[0] as any;
            safeParams += `${key}: ${val} `;
          }
        });
        if (this.params.length && !safeParams && throwIfNoValidArgs) {
          throw new Error('No valid parameters found');
        }
        if (safeParams) {
          safeParams = '(' + safeParams + ')';
        }
        mergedPayload = `${operationType} ${operationName} ${safeParams} {` + mergedPayload + '}';
        const unsupportedArgs = this.params
          .filter(param => !param['validated'])
          .map((el: { [key: string]: string | boolean }) => {
            const { validated: _validated, ...rest } = el;
            return (Object.keys(rest) as any)[0].replace('$', '');
          });

        return { query: mergedPayload, unsupportedArgs };
      } else {
        if (!this.safety) {
          isEqual(process.env['NODE_ENV'], 'development') &&
            console.log(
              `Instrospection utility failed. Safety is OFF. Proceeding with original operation payload`
            );
          return { query };
        } else {
          throw new Error(`Instrospection utility failed. Safety is ON`);
        }
      }
    } catch (err) {
      if (isEqual(process.env['NODE_ENV'], 'development')) {
        console.error(err);
      }
      if (!this.safety) {
        return { query };
      }
    }
  }
}

export { logWithSentry as logErrorsWithSentry } from '../api/sentry';

export const introspection = () => new GqlIntrospection();

// @deprecated - use introspection() instead (Reason - this shares the same instance across all imports)
export default new GqlIntrospection();

import { put, select, delay } from 'redux-saga/effects';
import { selectApiConfigs } from '../config/index';
import { showMessage, setIsLoaderVisible, MessageSeverity } from '../snackbar';
import { createExportRequestMutation, createExportRequestQuery } from '../api/exportEngineAssets';
import {
  createExportEngineAssetsFailed,
  createExportEngineAssetsInit,
  createExportEngineAssetsSuccess,
} from './exportEngineAssets.slice';
import { ICreateExportRequestInput, IExportEngineAsset } from '../types';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { preferredLanguageSelector } from '../auth';
import isEqual from 'lodash/isEqual';

export function* createExportRequestSaga(action: ReturnType<typeof createExportEngineAssetsInit>) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const locale: string = yield select(preferredLanguageSelector);
    const formatMessage = AIWareFormatMessage(locale);

    const exportRequestPayload: ICreateExportRequestInput = {
      tdoData: action.payload.tdoData,
      includeMedia: action.payload.includeMedia,
      includeAllEngineAssets: action.payload.includeAllEngineAssets,
      outputConfigurations: action.payload.outputConfigurations,
    } as any;

    const createExportRequest: IExportEngineAsset = yield createExportRequestMutation(
      apiConfigs,
      exportRequestPayload
    );

    // With the exportRequestId, we can now poll the export request status
    // from the dataDetailsViewerCreateExportRequest query
    let exportRequest: IExportEngineAsset = yield createExportRequestQuery(
      apiConfigs,
      createExportRequest?.id
    );

    yield put(setIsLoaderVisible(true));
    yield put(
      showMessage({
        content: isEqual(process.env['NODE_ENV'], 'development')
          ? 'Exporting assets...'
          : formatMessage({
              id: 'dataDetailsViewer.createExportRequestPending',
              defaultMessage: 'Exporting assets...',
              description: 'Message displayed when the user clicks the export button.',
            })!,
        severity: MessageSeverity.Info,
        removeIcon: false,
      })
    );

    while (exportRequest?.status !== 'complete' && exportRequest?.status !== 'failed') {
      yield delay(5000); // Poll every 5 seconds
      exportRequest = yield createExportRequestQuery(apiConfigs, createExportRequest?.id);

      if (exportRequest?.status === 'complete') {
        const assetUri = exportRequest?.assetUri;

        if (exportRequest?.assetUri) {
          const a = document.createElement('a');
          a.href = assetUri || '';
          document.body.appendChild(a);
          a.download = assetUri;
          a.dispatchEvent(new MouseEvent('click'));
          document.body.removeChild(a);
          window.URL.revokeObjectURL(assetUri);
        }

        yield put(
          showMessage({
            content: isEqual(process.env['NODE_ENV'], 'development')
              ? 'Download complete.'
              : formatMessage({
                  id: 'dataDetailsViewer.createExportRequestComplete',
                  defaultMessage: 'Download complete.',
                  description: 'Message displayed when the export request is complete.',
                })!,
            severity: MessageSeverity.Info,
            removeIcon: false,
          })
        );
      }

      if (exportRequest?.status === 'failed') {
        yield put(
          showMessage({
            content: isEqual(process.env['NODE_ENV'], 'development')
              ? 'Download Failed.'
              : formatMessage({
                  id: 'dataDetailsViewer.createExportRequestFailed',
                  defaultMessage: 'Export Request Failed. Please try again.',
                  description: 'Message displayed when the export request fails.',
                })!,
            severity: MessageSeverity.Error,
          })
        );
      }
    }

    const exportRequestResult: { assetUri: string } = yield createExportRequestQuery(
      apiConfigs,
      createExportRequest?.id
    );

    // Now you can use the zipFileUrl for further processing or download
    yield put(createExportEngineAssetsSuccess(exportRequestResult.assetUri));
  } catch (err) {
    console.error('Export Request Status:', err);
    yield put(createExportEngineAssetsFailed());
  }
}

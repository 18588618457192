import { namespace } from './slice';
import { IConfigState } from './interfaces';
import { createSelector } from '@reduxjs/toolkit';
import { sessionTokenSelector } from '../auth/selector';
import { IState } from '../auth/interfaces';

export const rootApiSelector = (state: IConfigState) => (state as any)?.[namespace]?.config?.apiRoot;

export const configSelector = (state: IConfigState) => (state as any)?.[namespace]?.config;

export const switchApiSelector = (state: IConfigState) => (state as any)?.[namespace]?.config?.switchAppUrl;

export const cmsUrlSelector = (state: IConfigState) => (state as any)?.[namespace]?.config?.cmsAppUrl;

export const graphEndpointSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.config?.graphEndpoint as string;

export const loginUrlSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.config?.loginUrl as string;

export const logoutUrlSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.config?.logoutUrl as string;

export const hidePasswordResetSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.initConfig?.hidePasswordReset as boolean;

export const logoutRedirectUrlSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.initConfig?.logoutRedirectUrl as string;

export const controllerNodeRedImageSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.config?.controllerNodeRedImage;

export const controllerUrlSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.config?.controllerUrl;

export const automateControllerUrlSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.config?.automateControllerUrl;

export const automateUrlSelector = (state: IConfigState) => (state as any)?.[namespace]?.config?.automateUrl;

export const baseUrlSelector = (state: IConfigState) => (state as any)?.[namespace]?.initConfig?.baseUrl;

export const initialConfigSelector = (state: IConfigState) => (state as any)?.[namespace]?.initConfig;

export const sessionTimeoutSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.config?.sessionTimeout;

export const betaFeaturesSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.initConfig?.betaFeatures;

export const appIdSelector = (state: IConfigState) => (state as any)?.[namespace]?.initConfig?.applicationId;

export const handleAuthSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.initConfig?.handleAuth;

export const selectDesktopUrl = (state: IConfigState) => (state as any)?.[namespace]?.config?.desktopAppUrl;

export const disableAppUnavailableModalSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.initConfig?.disableAppUnavailableModal === true;

export const oauthRedirectUriSelector = (state: IConfigState) =>
  (state as any)?.[namespace]?.initConfig?.oAuthRedirectUri ||
  `${window.location.protocol}//${window.location.hostname}`;

export const selectApiConfigs: (state: IConfigState & IState) => {
  apiRoot: string;
  graphQLEndpoint: string;
  token: string;
} = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: IConfigState) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: IConfigState) => string,
    sessionTokenSelector as (state: IState) => string,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);

import { PayloadAction } from '@reduxjs/toolkit';

export interface IInitialState {
  message: { [key: string]: unknown };
  duration: number;
  isLoaderVisible?: boolean;
}

export interface MessageData {
  content?: string;
  severity: MessageSeverity;
  actions?: Array<{
    reduxActionType: string | PayloadAction<any>;
    actionButtonText: string;
  }>;
  removeIcon?: boolean;
  maxWidth?: string | number;
}

export interface ShowMessageAction {
  payload: MessageData;
}

export enum MessageSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

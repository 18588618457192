import { createTheme } from '@mui/material/styles';
import { deepOrange, orange } from '@mui/material/colors';
// import { defaultThemeProps } from './defaultThemeProps';
import { sharedTypography } from './sharedTypography';
import { defaultOverrides } from './defaultThemeOverrides';
import { type VeritoneThemeOptions } from './themeInterfaces';

// these colors still need to be set
const colors = {
  primaryMain: orange[500],
  secondaryMain: deepOrange[900],
  textPrimary: '#E5E5E5',
  textSecondary: '#5C6269',
  tableheadColor: '#465364',
  tableRowSelectedBackground: '#F2F5F9',
  buttonContainedPrimaryBackgroundColor: '#1871E8',
  buttonContainedPrimaryHoverBackground: '#0D62D2',
  buttonDisabledColor: '#9CA8B4',
  buttonDisabledBackground: '#D5DFE9',
  buttonOutlinedBackground: '#EEF3F9',
  linkColor: '#6098D1',
  iconRootColor: '#555F7C',
  iconRootHover: '#1B1D1F',
  iconPrimaryHover: '#0D62D2',
  backgroundColor: '#1B1D1F',
  backgroundPaper: '#D5DFE9',
  divider: 'rgba(0,0,0,0.12)',
};

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.primaryMain,
    },
    secondary: {
      main: colors.secondaryMain,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
    background: {
      default: colors.backgroundColor,
    },
    divider: colors.divider,
    button: {
      containedPrimaryHoverBackground: colors.buttonContainedPrimaryHoverBackground,
      containedPrimaryBackgroundColor: colors.buttonContainedPrimaryBackgroundColor,
      disabledColor: colors.buttonDisabledColor,
      disabledBackground: colors.buttonDisabledBackground,
      outlineBackground: colors.buttonOutlinedBackground,
    },
  },
  spacing: 5,
  typography: sharedTypography(),
  overrides: {
    MuiDivider: {
      root: {
        background: colors.divider,
      },
    },
  },
} as VeritoneThemeOptions);

darkTheme.components = defaultOverrides(colors);

export default darkTheme;

export const getNodeRedVersionEdgeApi = async (API_URL: string) => {
  try {
    const res = await fetch(`${API_URL}/edge/v1/flow/engineId/image/`, {
      method: 'GET',
    });
    return res.json();
  } catch (error) {
    return {
      success: false,
    };
  }
};

import ReactDOM from 'react-dom/client';

import { PayloadAction } from '@reduxjs/toolkit';
import { select, takeEvery, put, call, fork } from 'redux-saga/effects';
import { AIWareThemeProvider, AIWareCacheProvider } from '@aiware/shared/theme';
import { getElement } from '@aiware/js/function';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import {
  IPanel,
  ISimplePanelConfig,
  IMicroFrontendConfig,
  IVeritoneAppbarPanelConfig,
  TRegistry,
  TRegistryComponent,
  TRegistryLookup,
  TabId,
} from '@aiware/js/interfaces';
import {
  hidePanel,
  mountPanel,
  renderPanel,
  name,
  unmountPanel,
  updatePanelMicroFrontendProps,
  openLinkOnNewTab,
  unmountAllPanel,
  updatePanelConfig,
} from './panel.actions';
import { Provider } from 'react-redux';
import { store } from '@aiware/shared/store';
import { selectIsFullscreenEnabled, setIsFullscreenEnabled } from '@aiware/shared/redux';
import { clickAwayListener, RefHolder } from './ClickAway';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const rootPostfix = 'root';

const getPanelElementId = (panelId: string) => `${panelId}-${rootPostfix}`;

const getNumericValue = (value: string | number): number =>
  typeof value === 'string' ? parseInt(value.replace('px', ''), 10) : value;

const AUTO_POSITIONED_CENTERS = [
  TabId.DATA_CENTER as string,
  TabId.RESOURCE_CENTER as string,
  TabId.ADMIN_CENTER as string,
  TabId.PROCESSING_CENTER as string,
];

const canCenterAutoPosition = (centerPanelId: string): boolean =>
  AUTO_POSITIONED_CENTERS.includes(centerPanelId);

interface IPanelConfigParent {
  panelConfig: { marginTop: number; marginStart: number; zIndex: number; ancestorCenterPanelId: string };
  panelId: string;
}

const getPanelZIndicies = (panels: IPanel<unknown, IVeritoneAppbarPanelConfig>[], isFullscreen: boolean) => {
  const startZIndex = 1001;
  // build a map of parent child relationships
  const panelRelationships: { [parentPanelId: string]: string[] } = {};
  panels.forEach(panel => {
    if (panel.panelConfig.parentPanelId) {
      // ensure parent panel has an entry
      if (!panelRelationships[panel.panelConfig.parentPanelId]) {
        panelRelationships[panel.panelConfig.parentPanelId] = [];
      }
      //populate parent panel with child panel
      panelRelationships[panel?.panelConfig?.parentPanelId]?.push(panel.panelId);
    }
  });

  /**
   * panels array is in order of first panel opened
   * panelRelationships array is in order of {parentId: random}: [children: order opened]
   *
   * Build the resulting array in order of
   * 1. first root panel opened
   *   2. fird child opened of first parent opened
   *     3. first child of first child of first parent...
   *   4. second child of first parent...
   * 5. second root panel opened
   */
  const resultOrder: string[] = [];
  const hasChildren = (panelId: string) => !!panelRelationships[panelId];
  const hasParent = (panelId: string) =>
    !!Object.values(panelRelationships).find(
      children => !!children.find(childPanelId => childPanelId === panelId)
    );
  const rootPanelIds = panels.filter(panel => !hasParent(panel.panelId)).map(rootPanel => rootPanel.panelId);
  const getDescendantsRecursive = (panelId: string) => {
    if (!hasChildren(panelId)) return [] as string[];
    const descendantOrder: string[] = [];
    const directDescendants = panelRelationships[panelId];
    directDescendants?.forEach(directDescendant => {
      // first add the direct descendant
      descendantOrder.push(directDescendant);
      // then get 2nd, 3rd, ...nth level descendants
      if (hasChildren(directDescendant)) {
        descendantOrder.push(...getDescendantsRecursive(directDescendant));
      }
    });

    return descendantOrder;
  };
  // find descendant tree for each root panel
  rootPanelIds.forEach(rootPanelId => {
    resultOrder.push(rootPanelId);
    resultOrder.push(...getDescendantsRecursive(rootPanelId));
  });

  // Ensure the "veritone panel" with the center icons is on top if not fullscreen
  if (!isFullscreen) {
    const veritonePanelIndex = resultOrder.findIndex(panelId => panelId === 'VERITONE_PANEL_ID');
    if (veritonePanelIndex !== -1) {
      const removed = resultOrder.splice(veritonePanelIndex, 1); // remove from current index
      resultOrder.push(...removed); // insert at the end
    }
  }

  // convert names to to z index and return values
  return resultOrder.map((panelId, arrayIndex) => ({ panelId, zIndex: startZIndex + arrayIndex }));
};

function* reOrderPanels() {
  const allPanels: IPanel<unknown, IVeritoneAppbarPanelConfig>[] = yield select(state => state.panels);
  const isFullscreen: boolean = yield select(selectIsFullscreenEnabled);

  // calculate new z index values based on panel parent/child relationships
  const autoZIndicies = getPanelZIndicies(allPanels, isFullscreen);
  // update z index on existing panels and rerender
  for (const entry of autoZIndicies) {
    const existingPanel = allPanels.find(panel => panel.panelId === entry.panelId) as IPanel<
      unknown,
      IVeritoneAppbarPanelConfig
    >;
    // Make sure we have a panel config to update
    if (entry.panelId && existingPanel && existingPanel.panelConfig && existingPanel.microFrontend) {
      const newConfig = { ...existingPanel.panelConfig, autoZIndex: entry.zIndex };
      const updatedPanel = { ...existingPanel, panelConfig: newConfig };
      yield put(updatePanelConfig({ panelId: entry.panelId, panelConfig: newConfig }));
      yield put(renderPanel(updatedPanel));
    }
  }
}

/**
 * Listens for widget mount/unmounts
 */
function mountPanelListenerSagaGenerator(registry: TRegistry, registryLookup?: TRegistryLookup) {
  const rootHash: { [key: string]: ReactDOM.Root } = {};
  return function* mountPanelListenerSaga() {
    yield takeEvery(
      mountPanel({} as IPanel<unknown, unknown>).type,
      function* (action: PayloadAction<IPanel<unknown, IVeritoneAppbarPanelConfig>>) {
        yield reOrderPanels();
        const {
          panelId,
          microFrontend: { name },
          panelConfig,
        } = action.payload;

        let pnl: TRegistryLookup | undefined = undefined;
        if (registryLookup) {
          pnl = (registry as any)[panelConfig.type] || (yield call(registryLookup, panelConfig.type));
          yield fork(registryLookup, name);
        }
        const Panel = pnl || (registry as any)[panelConfig.type];

        if (panelConfig.siblingPanelId) {
          const sibling: IPanelConfigParent = yield select(state =>
            state.panels.find((panel: { panelId: string }) => panel.panelId === panelConfig.siblingPanelId)
          );
          if (sibling && canCenterAutoPosition(sibling.panelConfig.ancestorCenterPanelId)) {
            const {
              panelConfig: { marginTop = 0, marginStart = 0 },
              panelId,
            } = sibling;
            const clientWidth = document?.getElementById(panelId)?.clientWidth || 650;
            panelConfig.marginTop = (
              panelConfig.marginTop === 0
                ? panelConfig.marginTop
                : getNumericValue(marginTop as any) || panelConfig.marginTop
            ) as any;
            panelConfig.marginStart = (
              panelConfig.marginTop === 0
                ? panelConfig.marginStart
                : getNumericValue(marginStart as any) + clientWidth
            ) as any;
            panelConfig.ancestorCenterPanelId = sibling.panelConfig.ancestorCenterPanelId;
          }
        } else if (panelConfig.parentPanelId) {
          const parent: IPanelConfigParent = yield select(state =>
            state.panels.find((panel: { panelId: string }) => panel.panelId === panelConfig.parentPanelId)
          );
          if (
            parent &&
            (canCenterAutoPosition(parent.panelId) ||
              canCenterAutoPosition(parent.panelConfig.ancestorCenterPanelId))
          ) {
            const {
              panelConfig: { marginTop = 0, marginStart = 0 },
            } = parent;
            panelConfig.marginTop = (
              panelConfig.marginTop === 0
                ? panelConfig.marginTop
                : getNumericValue(marginTop as any) || panelConfig.marginTop
            ) as any;
            panelConfig.marginStart = (
              panelConfig.marginTop === 0 ? panelConfig.marginStart : getNumericValue(marginStart as any)
            ) as any;
            panelConfig.ancestorCenterPanelId = parent.panelConfig.ancestorCenterPanelId || parent.panelId;
          }
        }

        const panelElementId = getPanelElementId(panelId);
        const el = getElement(panelElementId);
        el.classList.add('aiware-el');
        let root: ReactDOM.Root;
        if (!rootHash[panelElementId]) {
          root = ReactDOM.createRoot(el);
          rootHash[panelElementId] = root;
        } else {
          root = rootHash[panelElementId] as any;
        }
        root.render(
          <AIWareCacheProvider>
            <AIWareThemeProvider>
              <Provider store={store}>
                <ClickAwayListener onClickAway={clickAwayListener}>
                  <RefHolder>
                    <Panel type={'SIMPLE_PANEL'} panelId={panelId} data-test={`panel-${panelId}`}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', my: 20 }}>
                        <CircularProgress size={50} />
                      </Box>
                    </Panel>
                  </RefHolder>
                </ClickAwayListener>
              </Provider>
            </AIWareThemeProvider>
          </AIWareCacheProvider>
        );

        yield put(renderPanel(action.payload));
      }
    );

    yield takeEvery(
      renderPanel({} as IPanel<unknown, unknown>).type,
      function* (action: PayloadAction<IPanel<unknown, ISimplePanelConfig>>) {
        const {
          panelId,
          microFrontend: { name },
          panelConfig,
        } = action?.payload || {};

        let cmp: TRegistryComponent<unknown> = null;
        if (registryLookup) {
          cmp = (yield call(registryLookup, name)) as TRegistryComponent<unknown>;
        }
        const Component = cmp || (registry as any)[name] || (() => <div>Component Not Found.</div>);
        const Panel = (registry as any)[panelConfig.type];

        const panelElementId = getPanelElementId(panelId);
        const el = getElement(panelElementId);
        el.classList.add('aiware-el');
        const root = rootHash[panelElementId];
        root?.render(
          <AIWareCacheProvider>
            <AIWareThemeProvider>
              <Provider store={store}>
                <ClickAwayListener onClickAway={clickAwayListener}>
                  <RefHolder>
                    <Panel type={'SIMPLE_PANEL'} panelId={panelId} data-test={`panel-${panelId}`}>
                      <Component />
                    </Panel>
                  </RefHolder>
                </ClickAwayListener>
              </Provider>
            </AIWareThemeProvider>
          </AIWareCacheProvider>
        );
      }
    );

    yield takeEvery(unmountPanel('').type, function* (action: PayloadAction<string>) {
      const parentPanel: IPanelConfigParent = yield select(state =>
        state.panels.find(
          (panel: { panelConfig: { parentPanelId: string } }) =>
            panel?.panelConfig?.parentPanelId === action.payload
        )
      );
      if (parentPanel && Object.keys(parentPanel).length > 0) {
        yield put(unmountPanel(parentPanel.panelId));
      }
      setTimeout(() => {
        const panelElementId = getPanelElementId(action.payload);
        rootHash[panelElementId] && rootHash[panelElementId]?.unmount();
        delete rootHash[panelElementId];
      });
    });
    yield takeEvery(openLinkOnNewTab.type, function (action: PayloadAction<{ url: string }>) {
      const url = action?.payload?.url;
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
    });

    yield takeEvery(setIsFullscreenEnabled.type, function* () {
      yield reOrderPanels();
    });
  };
}

export function getPanelsModule(registry: TRegistry, registryLookup?: TRegistryLookup): ISagaModule<unknown> {
  return {
    id: name,
    reducerMap: {
      // [name]: reducer,
      panels: <T extends { panelConfig: { show: boolean }; id: string; props: unknown }>(
        state: { [key: string]: unknown }[],
        { type, payload }: { type: string; payload: T }
      ) => {
        switch (type) {
          case mountPanel({} as IPanel<unknown, unknown>).type: {
            const newState = [...state] as unknown as IPanel<
              IMicroFrontendConfig,
              IVeritoneAppbarPanelConfig
            >[];
            payload.panelConfig.show = true;
            const currentPanelConfig = payload.panelConfig as IVeritoneAppbarPanelConfig;
            const parentPanelId = currentPanelConfig?.parentPanelId;
            const dimmedStatus = currentPanelConfig?.dimmedStatus;
            if (dimmedStatus === 'dimAllParents') {
              const newPanels = newState.map(panel => ({
                ...panel,
                panelConfig: {
                  ...panel.panelConfig,
                  dimmed: (panel.panelConfig?.dimmed || 0) + 1,
                },
              }));
              return [...newPanels, payload];
            }
            if (parentPanelId && dimmedStatus) {
              const parentPanelIndex = state.findIndex(item => item['panelId'] === parentPanelId);
              newState[parentPanelIndex] = {
                ...newState[parentPanelIndex],
                panelConfig: {
                  ...newState[parentPanelIndex]?.panelConfig,
                  dimmed: (newState[parentPanelIndex]?.panelConfig?.dimmed || 0) + 1,
                } as any,
              } as any;
              return [...newState, payload];
            }
            return [...state, payload];
          }
          case unmountPanel('').type: {
            const currentPanelConfig = state.find(item => item['panelId'] === payload)?.[
              'panelConfig'
            ] as IVeritoneAppbarPanelConfig;
            const parentPanelId = currentPanelConfig?.parentPanelId;
            const dimmedStatus = currentPanelConfig?.dimmedStatus;
            const currentPanels = state.filter(item => item['panelId'] !== payload);
            if (dimmedStatus === 'dimAllParents') {
              return currentPanels.map(panel => ({
                ...panel,
                panelConfig: {
                  ...(panel['panelConfig'] as IVeritoneAppbarPanelConfig),
                  dimmed: ((panel['panelConfig'] as IVeritoneAppbarPanelConfig)?.dimmed || 0) - 1,
                },
              }));
            }
            if (parentPanelId && dimmedStatus) {
              const parentPanelIndex = state.findIndex(item => item['panelId'] === parentPanelId);
              if (parentPanelIndex === -1) return currentPanels;
              currentPanels[parentPanelIndex] = {
                ...currentPanels[parentPanelIndex],
                panelConfig: {
                  ...(currentPanels[parentPanelIndex]?.['panelConfig'] as IVeritoneAppbarPanelConfig),
                  dimmed:
                    ((currentPanels[parentPanelIndex]?.['panelConfig'] as IVeritoneAppbarPanelConfig)
                      ?.dimmed || 0) - 1,
                },
              };
              return currentPanels;
            }
            return currentPanels;
          }
          case hidePanel('').type: {
            let item = state.find(item => item['panelId'] === payload);
            const items = state.filter(item => item['panelId'] !== payload);
            item = {
              ...item,
              panelConfig: {
                ...(item?.['panelConfig'] as { panelConfig: unknown }),
                show: false,
              },
            };
            return [...items, item];
          }
          case unmountAllPanel('').type: {
            return state.map(item => ({
              ...item,
              panelConfig: {
                ...(item?.['panelConfig'] as { panelConfig: unknown }),
                show: false,
              },
            }));
          }
          case updatePanelMicroFrontendProps({} as { id: string; props: unknown }).type: {
            let item = state.find(item => item['panelId'] === payload.id);
            const items = state.filter(item => item['panelId'] !== payload.id);
            item = {
              ...item,
              microFrontend: {
                ...(item?.['microFrontend'] as { microFrontend: unknown }),
                config: payload.props,
              },
            };
            return [...items, item];
          }
          case updatePanelConfig({ panelId: '', panelConfig: {} }).type: {
            const { panelId, panelConfig } = payload as unknown as {
              panelId: string;
              panelConfig: { autoZIndex: string };
            };
            return state.map(existingPanel => {
              if (existingPanel['panelId'] === panelId) {
                return { ...existingPanel, panelConfig };
              }
              return existingPanel;
            });
          }
        }
        return state || [];
      },
    },
    sagas: [mountPanelListenerSagaGenerator(registry, registryLookup)],
    initialActions: [],
  };
}

import { IUser, IOrganization, LoadingStatus } from '@aiware/js/interfaces';
import { namespace } from './slice';

export enum AuthContext {
  ResetPassword = 'ResetPassword',
}

export type TStatus = 'idle' | 'loading' | 'success' | 'failure' | 'skipped' | 'ready';

export enum Status {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  failure = 'failure',
  skipped = 'skipped',
  ready = 'ready',
}

export interface IImpersonationUserDetails {
  userId: string;
  orgGuid: string;
  firstName: string;
  lastName: string;
}

export interface IForcePasswordReseUserDetails {
  userId: string;
  email: string;
}

export interface IInitialState {
  oAuthToken: string;
  oAuthStatus: TStatus;
  initStatus: TStatus;
  userId: string;
  sessionToken: string;
  user: IUser;
  status: TStatus;
  errorMessage: string | null;
  authContext: AuthContext | null;
  userIsActive: boolean;
  impersonationState: {
    isImpersonating: boolean;
    fetchTokenStatus: LoadingStatus;
    user: IImpersonationUserDetails | null;
  };
  requestPasswordResetState: {
    status: LoadingStatus;
    userId: string | null;
    email: string | null;
  };
}

export interface IState {
  [namespace]: IInitialState;
}

export interface TokenAction {
  type: string;
  payload: {
    token: string;
  };
}

export interface SetTokenExpirationAction {
  type: string;
  payload: {
    expirationTime: string;
  };
}

export interface ResetPasswordRequestAction {
  type: string;
  payload: {
    resetToken: string;
    password: string;
    username: string;
    organizationInviteId?: string;
    apiUrl?: string;
    baseUrl?: string;
  };
}

export interface ResetPasswordErrorAction {
  type: string;
  payload: {
    message: string;
  };
}

export interface UpdateSessionTokenAction {
  type: string;
  payload: {
    token: string;
  };
}

export interface ICurrentUser {
  token: string;
  userId: string;
  userName: string;
  email: string;
  kvp: {
    firstName: string;
    lastName: string;
  };
  lastUpdated: string;
  organization: IOrganization;
  tokenExpiration: string;
  preferredLanguage?: string;
  recentApplications?: string;
  impersonatorId?: string;
  impersonatorUserName?: string;
}

export interface IUserRights {
  me?: {
    roles?: { appName: string; description: string }[];
    userSettings?: { key: string; value: string }[];
  };
  myRights?: {
    operations: string[];
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState, ShowMessageAction } from './interfaces';

export const namespace = 'snackbar';

const initialState: IInitialState = {
  message: {},
  duration: 0,
  isLoaderVisible: false,
};

const snackbarSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    showMessage(state: IInitialState, action: ShowMessageAction) {
      state.message = {
        key: new Date().getTime(),
        ...action.payload,
      };
    },
    clearMessage: () => initialState,
    setDuration(state: IInitialState, action: PayloadAction<number>) {
      state.duration = action.payload;
    },
    resetDuration(state: IInitialState) {
      state.duration = initialState.duration;
    },
    setIsLoaderVisible(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoaderVisible = action.payload;
    },
  },
});

export const { showMessage, clearMessage, setDuration, resetDuration, setIsLoaderVisible } =
  snackbarSlice.actions;

const snackbarReducer = snackbarSlice.reducer;
export default snackbarReducer;

import { baseGraphQLApi } from './baseGraphQLApi';
import type { IInitConfig } from '@aiware/js/interfaces';
import { DESKTOP_APP_ID } from '@aiware/js/constants';

export const getApplicationsApi = async (
  API_URL: string,
  token?: string,
  offset = 0,
  limit = 20
): Promise<unknown> => {
  try {
    const res = await fetch(`${API_URL}/v1/admin/current-user/applications?offset=${offset}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      credentials: 'include',
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
  return;
};

export const getApplicationLogo = async (graphEndpoint: string, token: string): Promise<unknown> => {
  const operationName = 'getApplicationLogo';
  const query = `query ${operationName}($limit: Int, $accessScope: [AccessScope!]) {
    applications(limit: $limit, accessScope: $accessScope) {
      count
      records {
        id
        signedIconSvg
        signedIconUrl
      }
    }
  }`;

  try {
    return await baseGraphQLApi({
      query,
      operationName,
      graphEndpoint,
      token,
      variables: {
        limit: 1000,
        accessScope: ['owned', 'granted'],
      },
    });
  } catch (error) {
    console.error(error);
  }
  return;
};

export async function getAppIdFromUrl(config: IInitConfig) {
  let appId = config.applicationId || '';

  if (appId && appId.length > 0) {
    return appId;
  }

  try {
    const apiRoot = config.baseUrl.replace('/v3/graphql', '');
    const response = (await getApplicationsApi(apiRoot, config.authToken, 0, 99999)) as {
      results: any[];
    };
    const appResults = response?.results || [];
    const foundApp = appResults
      .filter(e => e.applicationUrl)
      .sort((a, b) => (a.applicationStatus > b.applicationStatus ? 1 : -1))
      .find(e => e.applicationUrl.includes(window.location.host));

    if (foundApp) {
      appId = foundApp.applicationId;
    }
  } catch (err) {
    console.error('@aiware/js: Unable to fetch applicationId');
  } finally {
    appId = appId || DESKTOP_APP_ID;
  }

  return appId;
}

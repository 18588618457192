import { mountPanel as mountPanelAction } from '@aiware/js/panel';
import { store } from '@aiware/shared/store';

class DataCenter {
  openImporterPanel() {
    const microFrontend = {
      name: 'DATA_CENTER_IMPORTER',
      config: {
        name: 'Local Importer',
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      marginStart: 80,
      size: 'large',
      dimmed: 0,
    };
    store.dispatch(
      mountPanelAction({
        panelId: 'DATA_CENTER_IMPORTER',
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  }
}

export default DataCenter;

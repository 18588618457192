import { isEmpty } from 'lodash';
import { GraphQLError } from 'graphql';
import { logErrorsWithSentry } from '../utils';

interface GQLResponse<T> {
  data: T;
  errors?: GraphQLError[];
}

/**
 * @param query
 * @param variables
 * @param operationName
 * @param graphEndpoint
 * @param token
 */
export function baseGraphQLApiWithError<T = any>({
  graphQLEndpoint,
  token,
  query,
  variables,
  operationName,
  rethrowOriginalError,
  throwWholeErrorObject,
  skipErrorIfData = false,
}: {
  graphQLEndpoint: string;
  token: string;
  query: string;
  variables?: Record<string, unknown>;
  operationName?: string;
  rethrowOriginalError?: boolean;
  throwWholeErrorObject?: boolean;
  skipErrorIfData?: boolean;
}): Promise<T> {
  const payload: {
    query: string;
    variables?: Record<string, unknown>;
    operationName?: string;
  } = { query };
  if (variables) payload.variables = variables;
  if (operationName) payload.operationName = operationName;

  return fetch(graphQLEndpoint, {
    method: 'post',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then((res): Promise<GQLResponse<T>> => res.json())
    .then(async resp => {
      if (!isEmpty(resp.errors)) {
        await logErrorsWithSentry(resp, payload);
        // Also make sure the data doesn't have resp.data.errors
        if (skipErrorIfData && resp.data && Object.keys(resp.data).length > 1) {
          return resp.data;
        }
        if (throwWholeErrorObject) {
          throw resp?.errors || 'Error';
        }
        if (!rethrowOriginalError) {
          throw new Error(operationName || 'Error');
        } else {
          throw new Error((resp?.errors || [])[0]?.message || 'Error');
        }
      }
      return resp.data;
    });
}

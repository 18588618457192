import { createTheme } from '@mui/material/styles';
import { defaultOverrides } from './defaultThemeOverrides';
import { sharedTypography } from './sharedTypography';
import { type VeritoneThemeOptions } from './themeInterfaces';

const colors = {
  primaryMain: '#1871E8',
  secondaryMain: '#555F7C',
  textPrimary: '#2A323C',
  textSecondary: '#5C6269',
  tableheadColor: '#465364',
  tableRowSelectedBackground: '#F2F5F9',
  buttonContainedPrimaryBackgroundColor: '#1871E8',
  buttonContainedPrimaryHoverBackground: '#0D62D2',
  buttonDisabledColor: '#9CA8B4',
  buttonDisabledBackground: '#D5DFE9',
  buttonOutlinedBackground: '#EEF3F9',
  linkColor: '#6098D1',
  iconRootColor: '#555F7C',
  iconRootHover: '#1B1D1F',
  iconPrimaryHover: '#0D62D2',
  backgroundDefault: '#FFF',
  backgroundPaper: '#FFF',
  divider: '#D5DFE9',
  labelColor: '#555555',
  pinkBrand: '#D43060',
};

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colors.primaryMain,
    },
    secondary: {
      main: colors.secondaryMain,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.buttonDisabledColor,
    },
    background: {
      default: colors.backgroundDefault,
      paper: colors.backgroundPaper,
      badge: colors.pinkBrand,
    },
    divider: colors.divider,
    info: {
      main: colors.labelColor,
    },
    button: {
      containedPrimaryHoverBackground: colors.buttonContainedPrimaryHoverBackground,
      containedPrimaryBackgroundColor: colors.buttonContainedPrimaryBackgroundColor,
      disabledColor: colors.buttonDisabledColor,
      disabledBackground: colors.buttonDisabledBackground,
      outlineBackground: colors.buttonOutlinedBackground,
    },
  },
  spacing: 5,
  typography: sharedTypography(),
} as VeritoneThemeOptions);

lightTheme.components = defaultOverrides(colors);

export default lightTheme;

import { all, put, select, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { appIdSelector, baseUrlSelector, sessionTimeoutSelector } from './selector';
import config from '../config.json';
import { getAppConfig } from '../api/getAppConfig';
import { getAppIdFromUrl } from '../api/apiApplication';
import { IConfig } from './interfaces';
import { DESKTOP_APP_ID } from '@aiware/js/constants';

// actions
import {
  getConfigSuccess,
  getInitConfig,
  getSessionTimeout,
  setSessionTimeout,
  setInitApplicationId,
} from './slice';
import { updateTokenRequest } from '..';
import { oAuthGrantReadyToStart, oAuthGrantSkip, sdkInitSuccess, sdkInitFailure } from '../auth/slice';
import { initStatusSelector } from '../auth/selector';
import { TStatus, Status } from '../auth/interfaces';

const getEnvironmentConfig = (baseUrl = 'https://api.us-1.veritone.com/v3/graphql'): IConfig => {
  const targetConfig = Object.values(config).find(
    item => item.graphEndpoint === baseUrl
  ) as unknown as IConfig;
  return targetConfig || (config['prod-us-1'] as unknown as IConfig);
};

type TConfig = {
  [x: string]: any;
};

function* handleAuth(flag: boolean) {
  if (flag) {
    yield put(oAuthGrantReadyToStart());
  } else {
    yield put(oAuthGrantSkip());
  }
}

export function* getConfigsSaga(action: ReturnType<typeof getInitConfig>) {
  const baseUrl: string = yield select(baseUrlSelector);
  const appId: string = yield select(appIdSelector);
  const token = action.payload.authToken;
  const initStatus: TStatus = yield select(initStatusSelector);
  if (initStatus === Status.success) {
    return;
  }
  if (token) {
    yield put(updateTokenRequest({ token }));
  }

  if (!appId) {
    const findAppId: string | undefined = yield call(getAppIdFromUrl, action.payload);

    if (findAppId) {
      yield put(setInitApplicationId(findAppId));
    }
  }

  const staticConfig = getEnvironmentConfig(baseUrl);

  // if known environment initialize right away
  if (staticConfig.graphEndpoint === baseUrl) {
    yield put(getConfigSuccess({ ...staticConfig }));
    yield put(sdkInitSuccess());
    yield call(handleAuth, !!action.payload.handleAuth);
    return;
  }

  yield call(handleAuth, !!action.payload.handleAuth);

  // if desktop app, get the config from the window object
  if (appId === DESKTOP_APP_ID) {
    const config = (window as unknown as Record<string, unknown>)['aiwareConfig'] as IConfig;

    if (config?.apiRoot && typeof config['baseUrl'] === 'string') {
      // set the graphEndpoint to the baseUrl
      config.graphEndpoint = config['baseUrl'];
      if (!config.graphEndpointWS) {
        config.graphEndpointWS = config.graphEndpoint.replace('https', 'wss');
      }
      if (config.apiRoot.includes('/v1/admin')) {
        // remove the /v1/admin from the apiRoot since sdk expects it to be removed
        config.apiRoot = config.apiRoot.replace('/v1/admin', '');
      }
      yield put(getConfigSuccess({ ...config }));
      yield put(sdkInitSuccess());
      return;
    }
  }

  // else fetch the config from the GraphQL
  try {
    const config: TConfig = yield getAppConfig(baseUrl, token);
    if (!config?.['data']?.staticAppConfig) {
      throw new Error('Failed to get app config from core-graphql');
    }
    yield put(getConfigSuccess({ ...config?.['data']?.staticAppConfig }));
    yield put(sdkInitSuccess());
  } catch (error) {
    console.log(error);
    const config = getEnvironmentConfig(baseUrl);
    yield put(getConfigSuccess({ ...config }));
    yield put(sdkInitFailure());
  }
}

export function* getSessionTimeoutSaga(action: ReturnType<typeof getSessionTimeout>) {
  const govEnvs = ['us-fed-1', 'us-fed-2', 'us-gov-1', 'us-gov-2'];
  const isGov = govEnvs.find(env => window.location.href.includes(env));
  let sessionTimeout = isGov ? 15 : 60;
  const sessionTimeoutFromConfig: number = yield select(sessionTimeoutSelector);
  const appId: string = yield select(appIdSelector);

  // if desktop app, get the config from the window object
  if (appId === DESKTOP_APP_ID && sessionTimeoutFromConfig > 0) {
    yield put(setSessionTimeout(sessionTimeoutFromConfig));
    return;
  }

  try {
    const baseUrl: string = yield select(baseUrlSelector);
    const token = action.payload;
    const config: TConfig = yield getAppConfig(baseUrl, token);
    sessionTimeout = config?.['data']?.staticAppConfig?.sessionTimeout || sessionTimeout;
    yield put(setSessionTimeout(sessionTimeout));
  } catch (e) {
    console.log(e);
    // default timeout if gql is not available
    yield put(setSessionTimeout(sessionTimeout));
  }
}

export default function* configsSaga() {
  yield all([takeEvery(getInitConfig.type, getConfigsSaga)]);
}

export function* watchSessionTimeout() {
  yield takeLatest(getSessionTimeout.type, getSessionTimeoutSaga);
}

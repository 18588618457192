import { IState } from './interfaces';
import { namespace } from './slice';

export const isIdleSelector = (state: IState) => (state as any)[namespace]?.isIdle;

export const callLogoutSelector = (state: IState) => (state as any)[namespace]?.callLogout;

export const clickedLogoutSelector = (state: IState) => (state as any)[namespace]?.clickedLogout;

export const callResetIdleTimerSelector = (state: IState) => (state as any)[namespace]?.callResetIdleTimer;

export const resetIdleTimerCallCounterSelector = (state: IState) =>
  (state as any)[namespace]?.resetIdleTimerCallCounter;

export const reloadPageSelector = (state: IState) => (state as any)[namespace]?.reloadPage;

export const messageScriptAddedSelector: (state: IState) => boolean = (state: IState) =>
  (state as any)[namespace]?.messageScriptAdded;

export const messageScriptAvailableSelector: (state: IState) => boolean = (state: IState) =>
  (state as any)[namespace]?.messageScriptAvailable;

export const globalMessageSelector: (state: IState) => JSX.Element | string | null = (state: IState) =>
  (state as any)[namespace]?.globalMessage;

export const globalMessageFollowUpActionTypeSelector: (state: IState) => string | null = (state: IState) =>
  (state as any)[namespace]?.globalMessageFollowUpActionType;

function get<T>(
  object: { [key: string]: T },
  path: string | string[],
  defaultValue: T
): { [key: string]: T } | T {
  const pathArr = Array.isArray(path) ? path : path.split('.');
  let index = 0;
  const length = pathArr.length;
  while (object != null && index < length) {
    object = object[(pathArr as any)[index++]] as unknown as { [key: string]: T };
  }
  return index && index === length && object ? (object as unknown as T) : defaultValue;
}
export default get;
import { fetchVeritoneGraphQLApi } from './fetchVeritoneGraphQLApi';
import * as gqlQueries from './graphQLQueries';
import { get } from 'lodash';
import _ from 'lodash';

let apiEndpoint: string;
export function setApiEndpoint(value: string) {
  apiEndpoint = value;
}

/**
 * Main request builder to fetch engine by giving id
 * @param engineId
 * @param limit - limit of engine's builds
 * @param authToken
 */
export const fetchEngine = async (engineId: string, limit: number, authToken: string) => {
  const variables = { engineId, limit };
  const { fetchEngineQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchEngineQuery, variables);
};

/**
 * Main request builder to fetch tasks by given engine id
 * @param params - see IFetchTasksParams interface
 * @param authToken
 */

interface FetchTasksInterface {
  [key: string]: string | number | boolean | Array<Record<string, unknown>>;
}

export const fetchTasks = async (params: FetchTasksInterface, authToken: string) => {
  const { fetchTasksQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchTasksQuery, {
    ...params,
  });
};

export const getSignedUrl = async (name: string, authToken: string) => {
  const variables = { name };
  const { getSignedUrlQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, getSignedUrlQuery, variables);
};

export const createSingleJob = async (
  uploadUrl: string,
  engineId: string,
  fields: Array<Record<string, unknown>>,
  authToken: string
) => {
  const variables = { uploadUrl, engineId, fields };
  const { createSingleJobQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, createSingleJobQuery, variables);
};

export const getJobStatus = async (jobId: string, authToken: string) => {
  const variables = { jobId };
  const { getJobStatusQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, getJobStatusQuery, variables);
};

export const getEngineResult = async (tdoId: string, authToken: string) => {
  const variables = { tdoId };
  const { engineResultQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, engineResultQuery, variables);
};

interface ScheduleJobsInterface {
  [key: string]: string | number | boolean | Array<Record<string, unknown>>;
}

export const fetchScheduledJobs = async (params: ScheduleJobsInterface, token: string) => {
  const { scheduledJobsQuery } = gqlQueries;
  return await fetchVeritoneGraphQLApi(apiEndpoint, token, scheduledJobsQuery, {
    ...params,
  });
};

export const sendEmail = async (email: string, subject: string, message: string, authToken: string) => {
  const variables = { email, subject, message };

  const { sendMailMutation } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, sendMailMutation, variables);
};

export const fetchApplications = async (authToken: string) => {
  const { fetchApplicationsQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchApplicationsQuery, {});
};

export const fetchApplicationById = async (authToken: string, applicationId: string) => {
  const { fetchApplicationByIdQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchApplicationByIdQuery, { applicationId });
};

export const fetchOrganizationName = async (authToken: string, organizationId: string) => {
  const { fetchOrganizationNameQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchOrganizationNameQuery, { organizationId });
};

/**
 * Main request builder to fetch engine by giving id
 * @param offset - limit of engine's builds
 * @param authToken
 * @param owned
 * @param offset
 */

export const fetchEngines = (
  { name, owned, offset }: { name: string; owned: string; offset: number },
  authToken: string
) => {
  const { fetchEnginesQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchEnginesQuery, {
    name,
    owned,
    offset,
  });
};

export const fetchTemplateGallery = (
  token: string,
  {
    isPublic,
    categories,
    tags,
    developers,
    limit,
    offset,
  }: {
    isPublic?: boolean;
    categories?: string[];
    tags?: string[];
    developers?: string[];
    limit?: number;
    offset?: number;
  }
) => {
  const { fetchTemplateGalleryQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, token, fetchTemplateGalleryQuery, {
    isPublic,
    categories,
    tags,
    developers,
    limit,
    offset,
  });
};

/**
 * Main request builder to fetch flow detail by giving id
 * @param name
 * @param owned
 * @param offset
 * @param limit - for pagination
 * @param authToken
 */

export const fetchFlows = async ({
  name,
  owned,
  offset,
  limit,
  authToken = '',
  isNotebook = false,
}: {
  name: string;
  owned: boolean;
  offset: number;
  limit: number;
  authToken?: string;
  isNotebook?: boolean;
}) => {
  let variables;
  if (name === '' && owned === null) {
    variables = { offset, limit };
  } else if (name !== '' && owned === null) {
    variables = { name, offset, limit };
  } else if (name === '' && owned !== null) {
    variables = { offset, limit, owned };
  } else {
    variables = { name, offset, limit, owned };
  }

  if (isNotebook) {
    variables = { ...variables, manifestRuntimeType: ['notebook'] };
  } else {
    variables = { ...variables, manifestRuntimeType: ['nodeRed'] };
  }

  const { fetchFlowsQuery, fetchFlowsQueryNoRuntimeType } = gqlQueries;
  let res;
  try {
    res = await fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchFlowsQuery, variables);
  } catch (e) {
    //This try catch was only added as a backwards compatability hotfix. This and the fetchFlowsQueryNoRuntimeType query should be cleaned up once deployments are up to date.
    try {
      res = await fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchFlowsQueryNoRuntimeType, variables);
    } catch (e) {
      console.log('fetch flows error: ', e);
    }
  }
  return res;
};

const fetchFlowsIds = async (authToken: string) => {
  const { fetchFlowsIdsQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchFlowsIdsQuery, {});
};

export const deleteEngine = (id: string, token: string) => {
  const { deleteEngineQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, token, deleteEngineQuery, { id });
};

export const getTemplateById = (id: string, token: string) => {
  const { getTemplateByIdQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, token, getTemplateByIdQuery, {
    flowId: id,
  });
};

//templates fallback
export const getTemplateByIdFallback = (id: string, token: string) => {
  const { getTemplateByIdQueryFallback } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, token, getTemplateByIdQueryFallback, { flowId: id });
};

export const createEngine = (engineJson: unknown, token: string) => {
  const { createEngineMutation } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, token, createEngineMutation, {
    engineJson,
  });
};

export const createEnginebuild = (engineBuildVariables: unknown, token: string) => {
  const { createEngineBuildMutation } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, token, createEngineBuildMutation, engineBuildVariables);
};

export const fetchTags = async (authToken: string) => {
  const response = await fetchFlowsIds(authToken);
  const flowsIds = get(response, 'data.flows.records');

  const { fetchTagByFlowIdQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, fetchTagByFlowIdQuery, { id: flowsIds[0].id });
};

/**
 *
 * @param authToken
 */

interface RecordItem {
  tags: string[];
}

interface ResultItem {
  records: RecordItem[];
}

interface ResultsData {
  [key: string]: ResultItem | undefined;
}

export const fetchAllTags = async (ids: [number], authToken: string) => {
  const { getGeneratedFetchTagsQueryFromListIds } = gqlQueries;
  const query = getGeneratedFetchTagsQueryFromListIds(ids);
  const results = await fetchVeritoneGraphQLApi(apiEndpoint, authToken, query, {});
  const data = get(results, 'data', {}) as ResultsData;

  const tags = Object.values(data)
    .filter(
      (item): item is ResultItem =>
        item !== undefined &&
        Array.isArray(item.records) &&
        item.records.length > 0 &&
        item.records[0]?.tags !== undefined
    )
    .map(item => item?.records?.[0]?.tags);

  let uniqueTags: Array<unknown> = [];
  _.forEach(tags, item => {
    uniqueTags = _.union(uniqueTags, item as Array<string>);
  });
  uniqueTags.sort(function (a: unknown, b: unknown) {
    return (a as string).toLowerCase().localeCompare((b as string).toLowerCase());
  });
  return uniqueTags;
};

export const createJobTemplate = async (
  authToken: string,
  engineId: string,
  cluster: string,
  uid1: string,
  uid2: string,
  uid3: string,
  endpoint: string
) => {
  const { createJobTemplateQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, createJobTemplateQuery, {
    engineId,
    cluster,
    uid1,
    uid2,
    uid3,
    endpoint,
  });
};

export const runJobTemplate = async (
  authToken: string,
  jobTemplateId: string,
  runMode: string,
  startDateTime: string,
  stopDateAndTime: string
) => {
  const { runJobTemplateQuery } = gqlQueries;
  const name = `scheduledJob-${jobTemplateId}-${startDateTime}`;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, runJobTemplateQuery, {
    jobTemplateId,
    startDateTime,
    stopDateAndTime,
    runMode,
    name,
  });
};

export const createJob = async (
  authToken: string,
  engineId: string,
  cluster: string,
  uid1: string,
  uid2: string,
  uid3: string,
  endpoint: string
) => {
  const { createJobQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, authToken, createJobQuery, {
    engineId,
    cluster,
    uid1,
    uid2,
    uid3,
    endpoint,
  });
};

export const isRevisionsExistOnServerRequest = async (token: string) => {
  const { isRevisionsExistOnServerQuery } = gqlQueries;
  return fetchVeritoneGraphQLApi(apiEndpoint, token, isRevisionsExistOnServerQuery, {});
};

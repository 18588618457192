import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';

interface SessionLink {
  link: WebSocketLink;
  token: string;
}

const links: Record<string, SessionLink> = {};

// get the sessionLink in the links collection
export const getMailboxSessionLink = (mailboxId: string): SessionLink | undefined => links[mailboxId];

// set the sessionLink in the links collection
const setMailboxSessionLink = (mailboxId: string, link: WebSocketLink, token: string): SessionLink => {
  links[mailboxId] = {
    link,
    token,
  };
  return (links as any)[mailboxId];
};

// extract the link from the sessionLink and check for matching tokens
const extractSessionLink = (sessionLink: SessionLink, sessionToken: string): WebSocketLink | undefined => {
  const { link, token } = sessionLink;
  if (sessionToken === token || sessionToken === '') {
    return link;
  }
  return undefined;
};

// create a new link for the given mailbox and token
const createSessionLink = (baseGraphQLUrl: string, mailboxId: string, sessionToken: string) =>
  new WebSocketLink({
    uri: baseGraphQLUrl,
    options: {
      reconnect: true,
      connectionParams: {
        authorization: `Bearer ${sessionToken}`,
        mailboxId: mailboxId,
      },
    },
  });

// fetch the matching session link for this mailbox from the links collection, or create it and add it to the collection
const setSessionLink = (baseGraphQLUrl: string, mailboxId: string, sessionToken: string): SessionLink => {
  let link: WebSocketLink | undefined;
  const sessionLink = getMailboxSessionLink(mailboxId);
  if (sessionLink) {
    link = extractSessionLink(sessionLink, sessionToken);
  }
  link = link || createSessionLink(baseGraphQLUrl, mailboxId, sessionToken);
  return setMailboxSessionLink(mailboxId, link, sessionToken);
};

export const createApolloClient = (link: ApolloLink) => {
  return new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });
};

export const createApolloWs = (baseGraphqlUrl: string, authToken: string, mailboxId: string) => {
  const sessionLink = setSessionLink(baseGraphqlUrl, mailboxId, authToken);
  return sessionLink.link;
};

export const createApolloHttp = (baseGraphqlUrl: string, authToken: string) => {
  const client = new HttpLink({
    uri: baseGraphqlUrl,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return client;
};

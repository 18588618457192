import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EventAction, IState } from './interfaces';

export const namespace = 'events';

const initialState: IState = {
  isIdle: false,
  callLogout: false,
  clickedLogout: false,
  callResetIdleTimer: false,
  resetIdleTimerCallCounter: 0,
  reloadPage: false,
  messageScriptAdded: false,
  messageScriptAvailable: false,
  globalMessage: null,
  globalMessageFollowUpActionType: null,
};

const eventsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setIsIdle(state: IState, action: PayloadAction<boolean>) {
      state.isIdle = action.payload;
    },
    setCallLogout(state: IState, action: PayloadAction<boolean>) {
      state.callLogout = action.payload;
    },
    setClickedLogout(state: IState, action: PayloadAction<boolean>) {
      state.clickedLogout = action.payload;
    },
    setCallResetIdleTimer(state: IState, action: PayloadAction<boolean>) {
      state.callResetIdleTimer = action.payload;
      state.resetIdleTimerCallCounter = action.payload ? 1 : 0;
    },
    setReloadPage(state: IState, action: PayloadAction<boolean>) {
      state.reloadPage = action.payload;
    },
    postNotificationCommand(_state: IState, _action: EventAction) {
      //
    },
    subscribeToEventsWebsocket(_state: IState) {
      //
    },
    setMessageScriptAdded(state: IState) {
      state.messageScriptAdded = true;
    },
    setMessageScriptAvailable(state: IState) {
      state.messageScriptAvailable = true;
    },
    showGlobalMessage(
      state: IState,
      action: PayloadAction<{ message: JSX.Element | string; followUpActionType?: string }>
    ) {
      state.globalMessage = action.payload.message;
      state.globalMessageFollowUpActionType = action.payload.followUpActionType || null;
    },
    hideGlobalMessage(state: IState) {
      state.globalMessage = null;
    },
    disposeGlobalMessageFollowUp(state: IState) {
      state.globalMessageFollowUpActionType = null;
    },
  },
});

export const {
  setIsIdle,
  setCallLogout,
  setClickedLogout,
  setCallResetIdleTimer,
  setReloadPage,
  postNotificationCommand,
  subscribeToEventsWebsocket,
  setMessageScriptAdded,
  setMessageScriptAvailable,
  showGlobalMessage,
  hideGlobalMessage,
  disposeGlobalMessageFollowUp,
} = eventsSlice.actions;

const eventsReducer = eventsSlice.reducer;
export default eventsReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '@aiware/js/interfaces';
import {
  IInitialState,
  TokenAction,
  SetTokenExpirationAction,
  UpdateSessionTokenAction,
  ResetPasswordRequestAction,
  ResetPasswordErrorAction,
  AuthContext,
  Status,
  IImpersonationUserDetails,
  IForcePasswordReseUserDetails,
} from './interfaces';

export const namespace = 'auth';

const initialState: IInitialState = {
  oAuthToken: '',
  oAuthStatus: Status.idle,
  initStatus: Status.idle,
  userId: '',
  sessionToken: '',
  user: {} as IUser,
  status: Status.idle,
  authContext: null,
  errorMessage: null,
  userIsActive: true,
  impersonationState: {
    isImpersonating: false,
    fetchTokenStatus: Status.idle,
    user: null,
  },
  requestPasswordResetState: {
    status: Status.idle,
    userId: null,
    email: null,
  },
};

const authSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    fetchUserRequest(state: IInitialState) {
      state.status = Status.loading;
    },
    fetchUserSuccess(state: IInitialState, action: PayloadAction<IUser>) {
      state.sessionToken = action.payload?.token || '';
      state.userId = action.payload?.userId || '';
      state.user = { ...action.payload };
      state.status = Status.success;
    },
    fetchUserError(state: IInitialState) {
      state.status = Status.failure;
    },
    updateTokenRequest(state: IInitialState, _action: UpdateSessionTokenAction) {
      state.status = Status.idle;
    },
    updateTokenSuccess(state: IInitialState, action: UpdateSessionTokenAction) {
      const { token } = action.payload;
      state.sessionToken = token;
      state.user.token = token;
      if (state.oAuthStatus === Status.success || state.oAuthStatus === Status.skipped) {
        state.status = Status.success;
      }
    },
    updateTokenError(state: IInitialState) {
      state.sessionToken = '';
      state.status = Status.failure;
    },
    updateUserLanguageStart(state: IInitialState, _action: PayloadAction<string>) {
      state.status = Status.loading;
    },
    updateUserLanguageSuccess(state: IInitialState, action: PayloadAction<string>) {
      state.user.preferredLanguage = action.payload;
      state.status = Status.success;
    },
    refreshTokenRequest(state: IInitialState, _action: TokenAction) {
      state.status = Status.loading;
    },
    refreshTokenSuccess(state: IInitialState, action: PayloadAction<{ refreshToken?: { token: string } }>) {
      state.sessionToken = action.payload?.refreshToken?.token || '';
      state.status = Status.success;
    },
    extendTokenRequest(state: IInitialState, _action: TokenAction) {
      state.status = Status.loading;
    },
    extendTokenSuccess(state: IInitialState, action: PayloadAction<{ token: string }>) {
      if (state.sessionToken !== action.payload.token) {
        state.sessionToken = action.payload.token;
      }
      state.status = Status.success;
    },
    setTokenExpiration(state: IInitialState, action: SetTokenExpirationAction) {
      state.user = {
        ...state.user,
        tokenExpiration: action.payload?.expirationTime,
      };
    },
    logoutRequest(state: IInitialState, _action: TokenAction) {
      state.status = Status.loading;
    },
    logoutSuccess(state: IInitialState) {
      state.status = Status.success;
    },
    logoutError(state: IInitialState) {
      state.status = Status.failure;
    },
    resetPasswordRequest(state: IInitialState, _action: ResetPasswordRequestAction) {
      state.status = Status.loading;
      state.errorMessage = null;
      state.authContext = AuthContext.ResetPassword;
    },
    resetPasswordError(state: IInitialState, action: ResetPasswordErrorAction) {
      const payload = action.payload;

      state.status = Status.failure;
      state.errorMessage = payload.message;
    },
    resetPasswordSuccess(state: IInitialState) {
      state.status = Status.success;
      state.errorMessage = null;
    },
    oAuthGrantReadyToStart(state: IInitialState) {
      state.oAuthStatus = Status.ready;
    },
    oAuthGrantStart(state: IInitialState) {
      state.oAuthStatus = Status.loading;
    },
    oAuthGrantSkip(state: IInitialState) {
      state.oAuthStatus = Status.skipped;
    },
    oAuthGrantSuccess(state: IInitialState, action: PayloadAction<string>) {
      state.oAuthToken = action.payload;
      state.oAuthStatus = Status.success;
    },
    oAuthGrantFailure(state: IInitialState) {
      state.oAuthStatus = Status.failure;
    },
    sdkInitSuccess(state: IInitialState) {
      state.initStatus = Status.success;
    },
    sdkInitFailure(state: IInitialState) {
      state.initStatus = Status.failure;
    },
    updateUserRecentApplicationsStart(state: IInitialState, _action: PayloadAction<string>) {
      state.status = Status.loading;
    },
    updateUserRecentApplicationsSuccess(state: IInitialState, action: PayloadAction<string>) {
      state.user.recentApplications = action.payload;
      state.status = Status.success;
    },
    impersonateUserStart(state: IInitialState, action: PayloadAction<IImpersonationUserDetails>) {
      console.log('Starting impersonate user fetch!', action.payload.firstName);
      state.impersonationState.fetchTokenStatus = Status.loading;
      state.impersonationState.user = action.payload;
    },
    impersonateUserSuccess(state: IInitialState) {
      state.impersonationState.fetchTokenStatus = Status.success;
    },
    impersonateUserError(state: IInitialState) {
      state.impersonationState.fetchTokenStatus = Status.failure;
    },
    requestPasswordResetStart(state: IInitialState, action: PayloadAction<IForcePasswordReseUserDetails>) {
      state.requestPasswordResetState.status = Status.loading;
      state.requestPasswordResetState.userId = action.payload.userId;
      state.requestPasswordResetState.email = action.payload.email;
    },
    requestPasswordResetSuccess(state: IInitialState) {
      state.requestPasswordResetState.status = Status.success;
    },
    requestPasswordResetError(state: IInitialState) {
      state.requestPasswordResetState.status = Status.failure;
    },
  },
});

// Actions
export const {
  fetchUserRequest,
  fetchUserSuccess,
  fetchUserError,
  refreshTokenRequest,
  refreshTokenSuccess,
  extendTokenRequest,
  extendTokenSuccess,
  setTokenExpiration,
  logoutRequest,
  logoutSuccess,
  logoutError,
  updateTokenRequest,
  updateTokenSuccess,
  updateTokenError,
  updateUserLanguageStart,
  updateUserLanguageSuccess,
  resetPasswordRequest,
  resetPasswordError,
  resetPasswordSuccess,
  oAuthGrantStart,
  oAuthGrantReadyToStart,
  oAuthGrantSkip,
  oAuthGrantSuccess,
  oAuthGrantFailure,
  sdkInitSuccess,
  sdkInitFailure,
  updateUserRecentApplicationsStart,
  updateUserRecentApplicationsSuccess,
  impersonateUserStart,
  impersonateUserError,
  requestPasswordResetStart,
  requestPasswordResetError,
  requestPasswordResetSuccess,
} = authSlice.actions;

const authReducer = authSlice.reducer;
export default authReducer;

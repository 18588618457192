import mockData from './activity.mock.json';

interface IRecord {
  id: string;
  event: string;
  userId: string;
  userName: string;
  organizationId: string;
  applicationId: string;
  createdDateTime: string;
  payload: {
    organizationId?: number;
    organizationName?: string;
    userName?: string;
    userId?: string;
  };
}
interface IUserActivityEvent {
  records: IRecord[];
}
interface IDataResponse {
  userActivityEvents: IUserActivityEvent;
}
interface IAPIResponse {
  success: boolean;
  data: IDataResponse;
}

export const fakeAPI = () =>
  new Promise<IAPIResponse>((res, _rej) => {
    setTimeout(
      () =>
        res({
          success: true,
          data: mockData,
        }),
      500
    );
  });

export const getActivityApi = async (): Promise<unknown> => {
  try {
    const { success, data } = await fakeAPI();

    if (success && data.userActivityEvents) {
      return {
        data: data.userActivityEvents,
        success: true,
      };
    }
    return {
      success: false,
      data: null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      message: error,
    };
  }
};

import { ISagaModule } from 'redux-dynamic-modules-saga';
import reducer from './slice';
import { namespace } from './slice';

// actions
export {
  setIsFullscreenEnabled,
  setIsConfirmCloseEnabled,
  setConfirmCloseLabel,
  DEFAULT_CONFIRM_PROMPT_LABEL,
} from './slice';

// selectors
export { selectIsFullscreenEnabled, selectIsConfirmCloseEnabled } from './selectors';

export function getUIStateModule(): ISagaModule<typeof reducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: reducer,
    },
    sagas: [],
  };
}

import { baseGraphQLApi } from './baseGraphQLApi';
import { baseGraphQLApiWithError } from './baseGraphQLApiWithError';
import { pick } from '@aiware/js/function';

export const getCurrentUserGraphQLApi = async (graphEndpoint: string, token?: string): Promise<unknown> => {
  const query = `{
      myRights{
        operations
      }
      me {
        id
        firstName
        lastName
        imageUrl
        email
  			userSettings{
          key
          value
        }
        organization{
          id
          imageUrl
          name
          businessUnit
          applications (limit: 999) {
            records {
              id
              name
              iconUrl
              iconSvg
              description
            }
          }
        }
        roles {
          id
          name
          appName
        }
      }
    }
  `;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token } as any);
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getCurrentUserApi = async (API_URL: string, token?: string): Promise<unknown> => {
  try {
    const res = await fetch(`${API_URL}/v1/admin/current-user`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
    return res.json();
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const resetBiometricApi = async (API_URL: string, token?: string): Promise<unknown> => {
  try {
    const res = await fetch(`${API_URL}/api/reset-request`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      credentials: 'include',
    });
    return res.json();
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const resetPasswordApi = async (email: string, graphEndpoint: string): Promise<unknown> => {
  const query = `
    mutation ($email: String!) {
      createPasswordResetRequest(input: { userName: $email }) {
        message
      }
    }
  `;

  const variables = {
    email,
  };

  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint });
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const updateUserAvatarHistory = async (data: string, graphEndpoint: string, token?: string) => {
  const query = `
   mutation ($input: UpdateCurrentUser!){
    updateCurrentUser(input: $input) {
      firstName
      lastName
    }
  }
`;
  const variables = {
    input: {
      userSetting: {
        key: 'avatarHistory',
        value: data,
      },
    },
  };
  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token } as any);
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const updateUserProfileApi = async (
  data: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
  },
  graphEndpoint: string,
  token?: string
): Promise<unknown> => {
  const query = `
   mutation ($input: UpdateCurrentUser!){
    updateCurrentUser(input: $input) {
      firstName
      lastName
    }
  }
`;
  // when updating, update these in the query, too
  const acceptableVals = ['firstName', 'lastName'];

  const variables = {
    input: {
      ...pick(data, acceptableVals),
      userSetting: {
        key: 'phoneNumber',
        value: data.phoneNumber,
      },
    },
  };
  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token } as any);
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const uploadUserAvatarApi = async (
  file: File | Blob,
  graphEndpoint: string,
  token?: string
): Promise<unknown> => {
  try {
    const res = await getWriteUrl(graphEndpoint, token);
    const { data } = res;
    const uploadUrlObj = data?.getSignedWritableUrl || {};
    if (!uploadUrlObj.url) {
      return ['No url found!'];
    }

    await fetch(uploadUrlObj.url, {
      method: 'PUT',
      body: file,
    });
    return uploadUrlObj.getUrl;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export async function getWriteUrl(graphEndpoint: string, token?: string) {
  const query = `
    query {
      getSignedWritableUrl {
        url
        unsignedUrl
        getUrl
      }
    }
  `;

  return await baseGraphQLApi({ query, graphEndpoint, token } as any);
}

export const updateUserAvatarApi = async (
  imageUrl: string,
  graphEndpoint: string,
  token?: string
): Promise<unknown> => {
  const query = `
   mutation ($input: UpdateCurrentUser!){
    updateCurrentUser(input: $input) {
      imageUrl
    }
  }
`;

  const variables = {
    input: {
      imageUrl,
    },
  };
  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token } as any);
  } catch (error) {
    console.error(error);
  }
  return;
};

export const updateCurrentUserSettingsApi = async (
  graphQLEndpoint: string,
  token: string,
  key: string,
  value: string
): Promise<unknown> => {
  const operationName = 'updateCurrentUser';
  const name = 'updateCurrentUser';
  const query = `
   mutation ${operationName}($input: UpdateCurrentUser!){
     ${name}(input: $input) {
      userSettings {
        key
        value
      }
    }
  }
`;
  const variables = {
    input: {
      userSetting: {
        key,
        value,
      },
    },
  };

  const result = await baseGraphQLApiWithError<{
    userSettings: {
      key: string;
      value: string;
    }[];
  }>({
    graphQLEndpoint,
    token,
    query,
    variables,
    operationName,
  });
  return (result as any)[name]?.userSettings;
};

export const updateUserSettingsApi = async (
  graphQLEndpoint: string,
  token: string,
  userId: string,
  key: string,
  value: string
): Promise<unknown> => {
  const operationName = 'updateUserSetting';
  const name = 'updateUserSetting';
  const query = `
   mutation ${operationName}($input: UpdateUserSetting){
     ${name}(input: $input) {
      key
      value
    }
  }
`;
  const variables = {
    input: {
      userId,
      key,
      value,
    },
  };

  const result = await baseGraphQLApiWithError<{
    key: string;
    value: string;
  }>({
    graphQLEndpoint,
    token,
    query,
    variables,
    operationName,
  });
  return (result as any)[name]?.value;
};

export const logoutUserApi = async (token: string, graphEndpoint: string): Promise<unknown> => {
  const query = `
    mutation logout($token: String!) {
      userLogout(token: $token )
    }
  `;
  const variables = {
    token,
  };
  try {
    const res = await baseGraphQLApi({
      query,
      variables,
      graphEndpoint,
      token,
    });
    return res;
  } catch (error) {
    return {
      success: false,
    };
  }
};

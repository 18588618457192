// import { IManifest } from '@aiware/os/interfaces';
// eslint-disable-next-line max-len

// eslint-disable-next-line import/prefer-default-export
export const fetchVeritoneGraphQLApi = async (
  apiEndpoint: string,
  token: string,
  query: string,
  variables: unknown,
  operationName?: string
) => {
  return fetch(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    credentials: 'include',
    body: JSON.stringify({
      query,
      variables,
      operationName,
    }),
  })
    .then(response => response.json())
    .then(parsedResponse => {
      if (parsedResponse.errors) {
        const errorMessage = `GraphQL Errors:\n${parsedResponse.errors.map(
          (error: { name: unknown; message: unknown }) => `${error.name}: ${error.message}\n`
        )}`;
        console.error('GraphQL Errors', parsedResponse.errors);
        throw new Error(errorMessage);
      }
      return parsedResponse;
    })
    .catch(error => {
      throw error;
    });
};

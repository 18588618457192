import { store } from '@aiware/shared/store';
import { IMicroFrontend, IVeritoneAppbarPanelConfig } from '@aiware/js/interfaces';
import { openInNewIcon } from '@aiware/shared/assets';
import AiwareSDKHelpers from '@aiware/js/sdk-helpers';
import { mountPanel as mountPanelAction } from '@aiware/js/panel';
import { AnyAction } from 'redux';
import { SdkError } from '@aiware/js/sdk-event-manager';

interface IHelpCenterActions {
  setCustomKnowledgeBaseUrlAction: (url: string) => AnyAction;
  setCustomDocsUrlAction: (url: string) => AnyAction;
  updateSelectedTabAction: (tabId: number, url: string) => AnyAction;
}

class HelpCenter extends AiwareSDKHelpers {
  public CUSTOM_HELP_PANEL_ID = 'CUSTOM_HELP_PANEL_ID';
  private actions: IHelpCenterActions;

  constructor({ actions }: { actions: IHelpCenterActions }) {
    super();
    this.actions = actions;
  }

  getPanelConfig() {
    const helpPanelMicroFrontend: IMicroFrontend<unknown> = {
      name: 'HELP_PANEL',
      config: {
        name: 'Help panel',
        dataId: 'helpDataId',
        type: 'SIMPLE_PANEL',
      },
    };
    const helpPanelConfig: IVeritoneAppbarPanelConfig = {
      panelId: this.CUSTOM_HELP_PANEL_ID,
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      size: 'large',
      header: {
        divider: true,
        title: 'HELP CENTER',
        actions: [
          {
            iconUrl: openInNewIcon,
            action: {
              type: 'OPEN_HELP_LINK',
            },
          },
        ],
      },
    };

    return {
      helpPanelMicroFrontend,
      helpPanelConfig,
    };
  }

  mountHelpPanel() {
    const { helpPanelMicroFrontend, helpPanelConfig } = this.getPanelConfig();
    store.dispatch(
      mountPanelAction({
        panelId: this.CUSTOM_HELP_PANEL_ID,
        microFrontend: helpPanelMicroFrontend,
        panelConfig: helpPanelConfig,
      })
    );
  }

  panels(): IVeritoneAppbarPanelConfig[] {
    return (store?.getState() as any).panels;
  }

  isCustomHelpPanelOpen(): boolean {
    return !!this.panels().find(
      (panel: IVeritoneAppbarPanelConfig) => panel.panelId === this.CUSTOM_HELP_PANEL_ID
    );
  }

  /**
   * Updates the `customDocsUrl` field in the store
   * and sets the active tab to 1
   *
   * @param {String} url
   */
  handleCustomDocsUrl(url: string) {
    if (this.isURLValid(url)) {
      store.dispatch(this.actions.setCustomDocsUrlAction(url));
      store.dispatch(this.actions.updateSelectedTabAction(1, ''));
    }
  }

  /**
   * Updates the `customKnowledgeBaseUrl` field in the store.
   * and sets the active tab to 0
   *
   * @param {String} url
   */
  handleCustomKnowledgeBaseUrl(url: string) {
    if (this.isURLValid(url)) {
      store.dispatch(this.actions.setCustomKnowledgeBaseUrlAction(url));
      store.dispatch(this.actions.updateSelectedTabAction(0, ''));
    }
  }

  /**
   * Opens the help center panel with provided url.
   * Sets the url to `knowledgeBaseUrl` in the `initConfig`
   * if the url param is not provided.
   *
   * @param {String} url
   * @param {Boolean} unmountAllPanels
   */
  openDocsPanel(url?: string, unmountAllPanels = true) {
    if (url && !this.isURLValid(url)) {
      const error = new SdkError(
        'Can not open the custom url! The url should be valid and start with http or https'
      );
      console.error(error.message);

      store.dispatch(this.actions.updateSelectedTabAction(1, ''));
      return;
    }

    if (this.isCustomHelpPanelOpen()) {
      this.handleCustomDocsUrl(url!);
      return;
    }

    if (unmountAllPanels) {
      this.unmountAllPanel();
    }

    this.mountHelpPanel();

    if (url) {
      this.handleCustomDocsUrl(url);
    } else {
      store.dispatch(this.actions.updateSelectedTabAction(1, ''));
    }
  }

  /**
   * Opens the help center panel with provided url.
   * Sets the url to `knowledgeBaseUrl` in the `initConfig`
   * if the url param is not provided.
   *
   * @param {String} url`
   */
  openKnowledgeBasePanel(url?: string) {
    if (url && !this.isURLValid(url)) {
      const error = new SdkError(
        'Can not open the custom url! The url should be valid and start with http or https'
      );
      console.error(error.message);

      store.dispatch(this.actions.updateSelectedTabAction(0, ''));
      return;
    }

    if (this.isCustomHelpPanelOpen()) {
      this.handleCustomKnowledgeBaseUrl(url!);
      return;
    }

    this.unmountAllPanel();
    this.mountHelpPanel();

    if (url) {
      this.handleCustomKnowledgeBaseUrl(url);
    } else {
      store.dispatch(this.actions.updateSelectedTabAction(0, ''));
    }
  }
}

export default HelpCenter;

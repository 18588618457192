import { useEffect, useRef } from 'react';

/**
 * a custom hooks for our setInterval operations
 * get more info in Dan Abramov's article https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback - callback function that will be called every `delay` ms.
 * @param delay - number representing the delay in ms. Set to `null` to "pause" the interval.
 */

const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef<typeof callback>();
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback && savedCallback.current && savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;

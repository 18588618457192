import { type FC, type PropsWithChildren } from 'react';
import { ThemeProvider, StyledEngineProvider, type Theme } from '@mui/material/styles';
import darkTheme from './dark';
import lightTheme from './light';

import BaselineStyles from './BaselineStyles';

interface AIWareThemeProviderProps {
  fullHeight?: boolean;
  theme?: Theme;
}

export const AIWareThemeProvider: FC<PropsWithChildren<AIWareThemeProviderProps>> = props => {
  const darkMode = { value: false }; /* useDarkMode(false); */
  const defaultTheme = darkMode.value ? darkTheme : lightTheme;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={props.theme || defaultTheme}>
        <BaselineStyles {...(props?.fullHeight !== undefined && { fullHeight: props.fullHeight })}>
          {props.children}
        </BaselineStyles>{' '}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AIWareThemeProvider;

import { selectApiConfigs } from '../config/index';
import { baseGraphQLApiWithError } from '../api/baseGraphQLApiWithError';
import { ICreateExportRequestInput, IExportEngineAsset } from '../types';

export const createExportRequestQuery = async (
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  exportRequestId: string
) => {
  const currentDateTime = new Date().toISOString().replace(/[-:]/g, '_');
  const fileName = `download-${currentDateTime}.zip`;
  const name = 'exportRequest';
  const query = `
    query {
      exportRequest(id: "${exportRequestId}") {
        id
        status
        modifiedDateTime
        assetUri(fileName: "${fileName}")
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: IExportEngineAsset;
  }>({
    query,
    ...apiConfigs,
    rethrowOriginalError: true,
  });

  return result[name];
};

export const createExportRequestMutation = async (
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  exportRequestPayload: ICreateExportRequestInput
) => {
  const { tdoData, includeMedia, includeAllEngineAssets, outputConfigurations } = exportRequestPayload;
  const operationName = 'createExportRequest';
  const name = 'createExportRequest';

  const query = `mutation ${operationName}(
  $input: CreateExportRequest!,
  ) {
    createExportRequest(input: $input) {
      id
      status
      organizationId
      createdDateTime
      modifiedDateTime
      requestorId
      assetUri
    }
  }
`;

  // NOTE: Our GqlIntrospection is not working properly with mutations.
  // For now, we are going with a try / catch approach where we try the unsafe mutation first, and if it fails, we try the safe mutation.
  try {
    // Try the unsafe mutation first

    const unsafeVariables = {
      input: {
        includeAllEngineAssets,
        includeMedia,
        outputConfigurations: outputConfigurations || [],
        tdoData: tdoData,
      },
    };

    const result = await baseGraphQLApiWithError<{
      [name]: IExportEngineAsset;
    }>({
      query,
      operationName,
      variables: unsafeVariables,
      ...apiConfigs,
      rethrowOriginalError: true,
    });

    return result[name];
  } catch (err) {
    // The unsafe mutation failed, try the safe mutation

    const safeVariables = {
      input: {
        includeMedia,
        outputConfigurations: outputConfigurations || [],
        tdoData: tdoData,
      },
    };

    const result = await baseGraphQLApiWithError<{
      [name]: IExportEngineAsset;
    }>({
      query,
      operationName,
      variables: safeVariables,
      ...apiConfigs,
      rethrowOriginalError: true,
    });

    return result[name];
  }
};

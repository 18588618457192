import { createIntl, type IntlShape, type MessageDescriptor, useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';

export interface Language {
  locale: string;
  name: string;
}

export type Messages = Record<string, string>;

export interface AiwareIntl {
  [key: string]: IntlShape;
}

export const langs: Language[] = [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'fr',
    name: 'French',
  },
  {
    locale: 'es',
    name: 'Spanish',
  },
];

interface TranslationPromises {
  [lang: string]: Promise<Messages>;
}

// a local cache of the translation json files
const translationPromises: TranslationPromises = {};

const esmImportRegex = /import\s+{.*initAiwareJs.*}\s*from\s*['"](?<jsloc>[^'")]+)/;
/**
 * Get the assets' base url by looking up the current js file's location.
 *
 * See: https://stackoverflow.com/questions/2255689
 * "This works because the browser loads and executes scripts in order,
 * so while your script is executing, the document it was included in is
 * sure to have your script element as the last one on the page"
 */
export const getAssetsBaseUrl = (): string => {
  const scripts = document.getElementsByTagName('script');
  if (!scripts.length) {
    return '/';
  }
  const preferredScript = Array.from(scripts).find(
    s => s.src.includes('js-core') || s.src.includes('aiware-ui') || s.innerHTML.match(esmImportRegex)
  );
  const backupScript = Array.from(scripts).find(s => s.src.includes('main.js'));

  const currentFileSrc =
    preferredScript?.src ||
    preferredScript?.innerHTML.match(esmImportRegex)?.groups?.['jsloc'] ||
    backupScript?.src ||
    '';
  const currentFileName =
    currentFileSrc
      .replace(/\/\+esm/, '')
      .split('/')
      .pop() || '';
  const assetsUrl = currentFileSrc.split(currentFileName)[0] || '/';

  return assetsUrl.replace(/\/+$/, '');
};

// fetches the translation json messages for the specified locale
export const fetchTranslationJson = async (locale: string): Promise<Messages> => {
  try {
    // eslint-disable-next-line no-prototype-builtins
    if (translationPromises.hasOwnProperty(locale)) {
      return (translationPromises as any)[locale];
    }

    const assetsBaseUrl = getAssetsBaseUrl();

    (translationPromises as any)[locale] = (await fetch(`${assetsBaseUrl}/locales/${locale}.json`)).json();
    return (translationPromises as any)[locale];
  } catch (e) {
    isEqual(process.env['NODE_ENV'], 'development') &&
      console.log('Error fetching translation json for `lang`: ' + locale, e);
    return Promise.resolve({});
  }
};

// stores an intl instance for each locale
export const intls: AiwareIntl = {};

const createIntlInstances = () => {
  if (Object.keys(intls).length) {
    return;
  }
  const promises = langs.map(async ({ locale }) => {
    const messages: Messages = await fetchTranslationJson(locale);
    return {
      locale,
      messages,
    };
  });

  Promise.all(promises)
    .then(results => {
      results.forEach(({ locale, messages }) => {
        intls[locale] = createIntl({
          locale,
          messages,
          defaultLocale: 'en',
        });
      });
    })
    .catch(error => {
      isEqual(process.env['NODE_ENV'], 'development') && console.log(error);
    });
};

// fetches an instance of the intl api
export const getIntlInstance = (locale: string): IntlShape => (intls as any)[locale];

// returns a message formatter function for the specified locale
export const AIWareFormatMessage =
  (locale: string) =>
  (message: MessageDescriptor, values?: { [key: string]: string }): string =>
    intls[locale]?.formatMessage(message, values) ?? '';

export function useAIWareFormatMessage(locale: string) {
  const useAIWareFormatMessage = (message: MessageDescriptor, values?: { [key: string]: string }) => {
    const defaultIntl = useIntl();
    const intl = intls[locale] ?? defaultIntl;
    return intl.formatMessage(message, values);
  };
  return useAIWareFormatMessage;
}

createIntlInstances();

export default {
  langs,
  intls,
  getIntlInstance,
  AIWareFormatMessage,
  useAIWareFormatMessage,
  getAssetsBaseUrl,
};

export type TGqlErr = {
  data?: {
    serviceMessage?: string;
  };
};

export type TApiConfigsSelector = (state: unknown) => {
  apiRoot: string;
  graphQLEndpoint: string;
  token: string;
};

export interface IExportEngineAsset {
  id: string;
  status: string;
  organizationId?: string;
  createdDateTime?: string;
  modifiedDateTime?: string;
  requestorId?: string;
  assetUri: string;
}

export interface ICreateExportRequestForTDO {
  tdoId: string;
  mentionId?: string;
  startOffsetMs?: number;
  stopOffsetMs?: number;
  startDate?: Date;
  stopDate?: Date;
}

export interface ICreateExportRequestInput {
  includeMedia: boolean;
  includeAllEngineAssets?: boolean;
  tdoData: ICreateExportRequestForTDO[];
  outputConfigurations?: any[];
}

export enum BrowseModule {
  DataCenter = 'DataCenter',
  Investigate = 'Investigate',
}

export enum DataCenterView {
  myFiles,
  sourceTree,
  jobs,
  folders,
  search,
  sdo,
  dashboard,
}